import { ErrorToast, SuccessToast, WarningToast } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import { useMutation } from '@tanstack/react-query'
import {
  BulkRespondOperation,
  InfoRequestsGroup,
  PostPlacementTypeEnum,
  RecallReturnRequestsGroup,
  StatusUpdateRequestsGroup,
} from 'src/utils/constants'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import BulkPostPlacementBasePage, {
  BulkPostPlacementBaseFormFilter,
} from '../components/BulkPostPlacementBasePage'
import { useRecoverGridFilter } from 'src/context/RecoverGridFilterContext'

interface BulkPostPlacementRespondParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
}

const BulkPostPlacementRespond: React.FC<BulkPostPlacementRespondParams> = ({
  businessOperations,
  postPlacementOperations,
  postPlacementPostOperations,
}) => {
  const { placerFilter, setPlacerFilter } = useRecoverGridFilter()
  const [form, setForm] = useState<BulkPostPlacementBaseFormFilter>({
    creditorId: placerFilter,
    vendorId: undefined,
    last4SSN: undefined,
    accountIDs: undefined,
    postPlacementType: undefined,
    type: undefined,
  })
  const [action, setAction] = useState<string>('response')
  const [processedFile, setProcessedFile] = useState<any[]>([])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)

  const mutationGetTemplate = useMutation({
    mutationFn: async () => {
      return postPlacementOperations?.getTemplateFile(
        form?.type,
        action === 'response',
        action === 'review'
      )
    },
    onSuccess: async (response: any) => {
      generateFileBlob(response)
    },
    onError: async () => {
      ErrorToast('Error downloading template')
    },
  })

  const getOperation = () => {
    if (
      RecallReturnRequestsGroup.concat(
        InfoRequestsGroup,
        StatusUpdateRequestsGroup
      ).includes(form?.type || 0)
    ) {
      if (action.toLowerCase() === 'review') {
        return BulkRespondOperation.DataSubmission
      }

      return BulkRespondOperation.AcceptReject
    }

    if ([PostPlacementTypeEnum.PifSif].includes(form?.type || 0))
      return BulkRespondOperation.Close

    return BulkRespondOperation.AcceptReject
  }

  const mutationUploadFile = useMutation({
    mutationFn: async () => {
      setIsProcessing(true)
      return postPlacementPostOperations?.uploadBulkRespond(
        form?.type,
        processedFile,
        getOperation()
      )
    },
    onSuccess: async (response: any) => {
      setShowUploadDialog(false)
      setIsProcessing(false)
      if (response?.data?.isSuccess) {
        if (response?.data?.data?.errors?.length === 0) {
          SuccessToast('Post Placement Respond created')
        } else {
          // TODO: REMOVE IT WHEN RESULT PAGE CREATED
          WarningToast('Post Placement Respond created')
          response?.data?.data?.errors.forEach((error: any) => {
            WarningToast(`Row: ${error.rowIndex} Error: ${error.title}`)
          })
        }
      } else {
        ErrorToast('Error processing file')
      }
    },
    onError: async (response: any) => {
      setIsProcessing(false)
    },
  })

  const GetPostPlacementRespondFileHeaders = () => {
    const headers: string[] = []
    if (
      RecallReturnRequestsGroup.concat(
        InfoRequestsGroup,
        StatusUpdateRequestsGroup
      ).includes(form?.type || 0) &&
      getOperation() === BulkRespondOperation.DataSubmission
    ) {
      headers.push('comments')
    } else if (![PostPlacementTypeEnum.PifSif].includes(form?.type || 0)) {
      headers.push('accept (y/n)')
      headers.push('reject reason')
    }

    headers.push('id')
    return headers
  }

  const processFile = (rawData: any[]) => {
    let items = rawData.map((_item: any) => {
      const normalizedItem = Object.fromEntries(
        Object.entries(_item).map(([key, value]) => [key.toLowerCase(), value])
      )

      return {
        rowIndex: normalizedItem.rowindex,
        requestId: normalizedItem.id,
        accept: String(normalizedItem['accept (y/n)']).toLowerCase() === 'y',
        requestedInfo: normalizedItem.comments,
        rejectReason: normalizedItem['reject reason'],
      }
    })

    setProcessedFile(items)
  }

  return (
    <BulkPostPlacementBasePage
      businessOperations={businessOperations}
      postPlacementOperations={postPlacementOperations}
      pageTitle="Respond"
      showActionDropDown={true}
      formFilter={form}
      setForm={setForm}
      mutationGetTemplate={mutationGetTemplate}
      fileHeaders={GetPostPlacementRespondFileHeaders()}
      processFile={processFile}
      mutationUploadFile={mutationUploadFile}
      isProcessing={isProcessing}
      showUploadDialog={showUploadDialog}
      setShowUploadDialog={setShowUploadDialog}
      action={action}
      setAction={setAction}
      setPlacerFilter={setPlacerFilter}
    ></BulkPostPlacementBasePage>
  )
}

export default BulkPostPlacementRespond
