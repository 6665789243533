import { IFileMap, TypeFieldEnum } from 'everchain-uilibrary'

export function getTransactionFileMapRepository(): IFileMap {
  return {
    fields: [
      {
        name: 'ECAID',
        description: 'ECAID',
        isRequired: false,
        key: true,
      },
      {
        name: 'Lender',
        description: 'Original Placer',
        isRequired: false,
        key: true,
      },
      {
        name: 'LoanId',
        description: 'Account Identifier In Client System',
        isRequired: false,
        key: true,
      },
      {
        name: 'DateMade',
        description: 'Date Made',
        isRequired: true,
        key: false,
      },
      {
        name: 'DatePosted',
        description: 'Date Posted',
        isRequired: true,
        key: false,
      },
      {
        name: 'PaymentID',
        description: 'Payment id',
        isRequired: false,
        key: false,
      },
      {
        name: 'PrincipalPayment',
        description: 'Principal Payment',
        isRequired: false,
        key: false,
      },
      {
        name: 'InterestPayment',
        description: 'Interest Payment',
        isRequired: false,
        key: false,
      },
      {
        name: 'FeePayment',
        description: 'Fee Payment',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalPaymentAmount',
        description: 'Total Payment Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'CreditID',
        description: 'Credit ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'PrincipalCredit',
        description: 'Principal Credit',
        isRequired: false,
        key: false,
      },
      {
        name: 'InterestCredit',
        description: 'Interest Credit',
        isRequired: false,
        key: false,
      },
      {
        name: 'FeeCredit',
        description: 'Fee Credit',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalCreditAmount',
        description: 'Total Credit Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'CreditID',
        description: 'Credit ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewFeeID',
        description: 'New Fee ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewFee',
        description: 'New Fee',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewFeeAmount',
        description: 'New Fee Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewInterestID',
        description: 'New Fee ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewInterest',
        description: 'New Interest ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewInterestAmount',
        description: 'New Interest Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'PaymentReversalID',
        description: 'Payment Reversal ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'AssociatedPaymentID',
        description: 'Associated Payment ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'PrincipalPaymentReversal',
        description: 'Principal Payment Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'InterestPaymentReversal',
        description: 'Interest Payment Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'FeePaymentReversal',
        description: 'Fee Payment Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalPaymentReversal',
        description: 'Total Payment Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'CreditReversalID',
        description: 'Credit Reversal ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'CreditAssociatedID',
        description: 'Credit Associated ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'PrincipalCreditReversal',
        description: 'Principal Credit Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'InterestCreditReversal',
        description: 'Interest Credit Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'FeeCreditReversal',
        description: 'Fee Credit Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalCreditReversal',
        description: 'Total Credit Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewFeeReversalID',
        description: 'New Fee Reversal ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewFeeAssociatedID',
        description: 'New Fee Associated ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewFeeAmountReversal',
        description: 'New Fee Amount Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewInterestReversalID',
        description: 'New Interest Reversal ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewInterestAssociatedID',
        description: 'New Interest Associated ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'NewInterestAmountReversal',
        description: 'New Interest Reversal',
        isRequired: false,
        key: false,
      },
      {
        name: 'UpdatedTotalAccountPrincipal',
        description: 'Updated Total Account Principal',
        isRequired: false,
        key: false,
      },
      {
        name: 'UpdatedTotalAccountFee',
        description: 'Updated Total Account Fee',
        isRequired: false,
        key: false,
      },
      {
        name: 'UpdatedTotalAccountInterest',
        description: 'Updated Total Account Interest',
        isRequired: false,
        key: false,
      },
      {
        name: 'UpdatedTotalBalance',
        description: 'Updated Total Account Balance',
        isRequired: false,
        key: false,
      },
    ],
  }
}

export function getRecallFileMapRepository(): IFileMap {
  return {
    fields: [
      {
        name: 'ECAID',
        description: 'ECAID',
        isRequired: false,
        key: true,
      },
      {
        name: 'Lender',
        description: 'Original Placer',
        isRequired: false,
        key: true,
      },
      {
        name: 'LoanId',
        description: 'Account Identifier In Client System',
        isRequired: false,
        key: true,
      },
    ],
  }
}

export function getAccountFileMapRepository(): IFileMap {
  return {
    fields: [
      {
        name: 'Lender',
        description: 'Original Placer',
        isRequired: true,
        key: true,
      },
      {
        name: 'LoanId',
        description: 'Account Identifier In Client System',
        isRequired: true,
        key: true,
      },
      {
        name: 'Product',
        description:
          'Loan Product (Online Payday Loan, Installment Loans,Store Front Payday Loans, Online & Store Front Payday Loans)',
        isRequired: false,
        key: false,
      },
      {
        name: 'BuyersOnTitle',
        description: '0 = Fresh from issuer, 1 = 1 Buyer and so on. - Max of 5',
        isRequired: false,
        key: false,
      },
      {
        name: 'Seller',
        description: 'If not Originator (required if above is anything but 0)',
        isRequired: false,
        key: false,
      },
      {
        name: 'Affinity',
        description: 'Affinity',
        isRequired: false,
        key: false,
      },
      {
        name: 'APR',
        description: 'APR',
        isRequired: true,
        key: false,
      },
      {
        name: 'AccountSecured',
        description: 'AccountSecured',
        isRequired: true,
        key: false,
      },
      {
        name: 'OriginalLoanAmount',
        description: 'Original Loan Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalDate',
        description: 'Original Loan Date',
        isRequired: true,
        key: false,
      },
      {
        name: 'AdditionalAccountNumber1',
        description: 'AdditionalAccountNumber1',
        isRequired: false,
        key: false,
      },
      {
        name: 'AdditionalAccountNumber2',
        description: 'AdditionalAccountNumber2',
        isRequired: false,
        key: false,
      },
      {
        name: 'AdditionalAccountNumber3',
        description: 'AdditionalAccountNumber3',
        isRequired: false,
        key: false,
      },
      {
        name: 'BureauAccountCreditReportedTo',
        description: 'BureauAccountCreditReportedTo',
        isRequired: false,
        key: false,
      },
      {
        name: 'CoBorrowerFlag',
        description: 'CoBorrowerFlag',
        isRequired: true,
        key: false,
      },
      {
        name: 'NumberofRefinances',
        description: 'NumberofRefinances',
        isRequired: false,
        key: false,
      },
      {
        name: 'ConsumerCreditScore_TimeofLending',
        description: 'ConsumerCreditScore_TimeofLending',
        isRequired: false,
        key: false,
      },
      {
        name: 'SSN',
        description: 'Debtor SSN',
        isRequired: true,
        key: false,
      },
      {
        name: 'DOB',
        description: 'Debtor Date of Birth',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'FirstName',
        description: 'Debtor First name',
        isRequired: true,
        key: true,
      },
      {
        name: 'LastName',
        description: 'Debtor Last name',
        isRequired: true,
        key: true,
      },
      {
        name: 'MI',
        description: 'Debtor Middle Initial',
        isRequired: false,
        key: false,
      },
      {
        name: 'Address1',
        description: 'Debtor Street Address 1',
        isRequired: true,
        key: false,
      },
      {
        name: 'Address2',
        description: 'Debtor Street Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'City',
        description: 'Debtor City',
        isRequired: true,
        key: false,
      },
      {
        name: 'State',
        description:
          'Debtor State - Two letter abbreviation of any of the 50 states  (i.e. CA, NY, FL, etc)',
        isRequired: true,
        key: false,
      },
      {
        name: 'Zip',
        description: 'Debtor Zip - Characters for Canada Postal Code ',
        isRequired: true,
        key: false,
      },
      {
        name: 'Country',
        description:
          'Debtor Country Country Codes   The 2-letter codes as  supplied by the ISO (International Organization for Standardization)',
        isRequired: false,
        key: false,
      },
      {
        name: 'HomeStatus',
        description:
          'Home Ownership Status - One letter abbreviation for home ownership status - • W – Own - • R – Rent • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'PhnCountryCode',
        description: 'is the international code used to dial ',
        isRequired: false,
        key: false,
      },
      {
        name: 'PhnCityCode',
        description: 'is the local city code',
        isRequired: false,
        key: false,
      },
      {
        name: 'HomePhone',
        description: 'Debtor Home Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'MobilePhone',
        description: 'Debtor Mobile Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'WorkPhone',
        description: 'Debtor Work Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'WorkPhoneExtension',
        description: 'Debtor Work Phone Extension',
        isRequired: false,
        key: false,
      },
      {
        name: 'Email',
        description: 'Debtor Email Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'Employer',
        description: 'Debtor Employer name',
        isRequired: false,
        key: false,
      },
      {
        name: 'JobTitle',
        description: 'Debtor Job Title',
        isRequired: false,
        key: false,
      },
      {
        name: 'EmploymentStartDate',
        description: 'Date Employment Started',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'EmploymentEndDate',
        description: 'Date Employment Ended',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'PayMethod',
        description:
          'How do You Receive your Pay - One letter abbreviation for pay check payment method   • P – Paper - • D – Direct Deposit - • B – Barter -• O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'EmployeeLastPaid',
        description: 'Date Employee was last paid',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'EmployeeSupervisor',
        description: 'name of Employees Supervisor',
        isRequired: false,
        key: false,
      },
      {
        name: 'EmployeeSupervisorPhone',
        description: 'Phone number of Employees Supervisor',
        isRequired: false,
        key: false,
      },
      {
        name: 'PayFrequency',
        description:
          'How Often are You Paid - One letter abbreviation for pay check frequency   • W – Weekly - • B – Biweekly - • S – Semi-Monthly-• M – Monthly - • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'DayOfPay',
        description: 'Date customer is paid by employer',
        isRequired: false,
        key: false,
      },
      {
        name: 'BankAccountType',
        description:
          'Bank Account Type - One letter abbreviation for bank account type   • C – Checking - • S – Savings - • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'Bankname',
        description: 'Debtor Checking Account Bank name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Routing',
        description: 'Debtor Checking Account Routing (ABA) Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'BankAccountNumber',
        description: 'Debtor Bank Account Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'FundDate',
        description: 'Debt Disbursement Date (Date format could be YYYY/MM/DD)',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'FundAmount',
        description: 'Debt Disbursement Amount',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PrincipalBalance',
        description: 'Total Principal Balance',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'InterestBalance',
        description: 'Total Interest',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'FeeBalance',
        description: 'Any Fee charged on loan other than interest',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'TotalBalance',
        description: 'Original Balance',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'TotalAmountPaid',
        description: 'Total Amount Paid on Note/Loan',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'DefaultDate',
        description: 'Default Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'WriteOffDate',
        description: 'Account Write Off Date',
        isRequired: true,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'WriteOffReason',
        description: 'Account Write-off Reason',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastPaymentDate',
        description: 'Last payment Date to Client',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'LastPaymentAmount',
        description: 'Last Payment Amount to Client',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'LastPaymentStatus',
        description:
          'Last Payment Status -One letter abbreviation for payment status   • C – Cleared - • R – Returned - • W – Written-off -• L – Lost - • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'InitialReturnCode',
        description:
          'First Payment Default Return Code - Must be Valid Code of R01 - R33',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastReturnCode',
        description:
          'Last Payment Default Return Code - Must be Valid Code of R01 - R33',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastWorkedDate',
        description:
          'Date Account Last Worked Internally - (Date format could be YYYY/MM/DD)',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'LastDueDate',
        description:
          'The Last Due Date That Resulted in Default (Date format could be YYYY/MM/DD)',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'Ref1name',
        description: 'First Reference name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref1Phone',
        description: 'First Reference Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref1Relationship',
        description: 'First Reference Relationship',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref2name',
        description: 'Second Reference name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref2Phone',
        description: 'Second Reference Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref2Relationship',
        description: 'Second Reference Relationship',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref3name',
        description: 'Third Reference name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref3Phone',
        description: 'Third Reference Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref3Relationship',
        description: 'Third Reference Relationship',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref4name',
        description: 'Fourth Reference name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref4Phone',
        description: 'Fourth Reference Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref4Relationship',
        description: 'Fourth Reference Relationship',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref5name',
        description: 'Fifth Reference name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref5Phone',
        description: 'Fifth Reference Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Ref5Relationship',
        description: 'Fifth Reference Relationship',
        isRequired: false,
        key: false,
      },
      {
        name: 'IDType',
        description:
          'Driving License, State Issued ID, Permanent Resident Card, Passport',
        isRequired: false,
        key: false,
      },
      {
        name: 'IDNumber',
        description: 'Identification Number of the ID',
        isRequired: false,
        key: false,
      },
      {
        name: 'IDState',
        description:
          'Two letter abbreviation of any of the 50 states (i.e. CA, NY, FL, etc) or country sub-entity',
        isRequired: false,
        key: false,
      },
      {
        name: 'Storename',
        description: 'Store name',
        isRequired: false,
        key: false,
      },
      {
        name: 'StoreAddress',
        description: 'Store Street Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'StoreCity',
        description: 'Store City',
        isRequired: false,
        key: false,
      },
      {
        name: 'StoreState',
        description:
          'Two letter abbreviation of any of the 50 states (i.e. CA, NY, FL, etc) or country sub-entity',
        isRequired: false,
        key: false,
      },
      {
        name: 'MerchantStoreID',
        description: 'Could be anything',
        isRequired: false,
        key: false,
      },
      {
        name: 'FirstDelinquencyDate',
        description: 'First Delinquency Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'LastDelinquencyDate',
        description: 'Last Delinquency Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'TotalPaidPriorToDefault',
        description: 'Total Paid Prior To Default',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'TotalPaidPostDefault',
        description: 'Total Paid After Default',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'LastMissedPaymentDate',
        description: 'Last Missed Payment Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'InterestRate',
        description: 'Interest Rate',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee1',
        description: 'Fee 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee2',
        description: 'Fee 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee3',
        description: 'Fee 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee4',
        description: 'Fee 4',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee5',
        description: 'Fee 5',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerSSN',
        description: 'Co-Debtor SSN',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerDOB',
        description: 'Co-Debtor Date of Birth',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'Co-BorrowerFirstname',
        description: 'Co-Debtor First name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerLastname',
        description: 'Co-Debtor Last name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMI',
        description: 'Co-Debtor Middle Initial',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerAddress1',
        description: 'Co-Debtor Street Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerAddress2',
        description: 'Co-Debtor Street Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerCity',
        description: 'Co-Debtor City',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerState',
        description: 'Co-Debtor State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerZip',
        description: 'Co-Debtor Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerCountry',
        description: 'Co-Debtor Country Codes',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerHomeStatus',
        description: 'Home Ownership Status',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerPhnCountryCode',
        description: 'Co-Debtor International Phone Country Code',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerPhnCityCode',
        description: 'Co-Debtor Local City Phone Code',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerHomePhone',
        description: 'Co-Debtor Home Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMobilePhone',
        description: 'Co-Debtor Mobile Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerWorkPhone',
        description: 'Co-Debtor Work Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerWorkPhoneExtension',
        description: 'Co-Debtor Work Phone Extension',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmail',
        description: 'Co-Debtor Email Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployer',
        description: 'Co-Debtor Employer name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerJobTitle',
        description: 'Co-Debtor Job Title',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerAddress',
        description: 'Co-Debtor Employer Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerCity',
        description: 'Co-Debtor Employer City',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerState',
        description: 'Co-Debtor Employer State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerZip',
        description: 'Co-Debtor Employer Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerPhone',
        description: 'Co-Debtor Employer Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerDriverLicense',
        description: 'Co-Debtor Driver License',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerLanguage',
        description: 'Co-Debtor Language',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingAddress1',
        description: 'Co-Debtor Mailing Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingAddress2',
        description: 'Co-Debtor Mailing Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingCity',
        description: 'Co-Debtor City',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingState',
        description: 'Co-Debtor State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingCountry',
        description: 'Co-Debtor State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingZip',
        description: 'Co-Debtor Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementAgreement',
        description: 'SettlementAgreement',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementOfferAmount',
        description: 'SettlementOfferAmount',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementOfferDate',
        description: 'Settlement Offer Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'SettlementExpirationDate',
        description: 'Settlement Expiration Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'PaymentFrequency',
        description: 'Payment Frequency',
        isRequired: false,
        key: false,
      },
      {
        name: 'DayPaymentWasScheduledForProcessing',
        description: 'Day Payment Was Scheduled For Processing',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'Language',
        description: 'Language',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalNumberOfNSFFees',
        description: 'Total Number Of NSF Fees',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Numeric,
      },
      {
        name: 'TotalNSFFeesAmt',
        description: 'Total NSF Fees Amt',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'RecoveryOpeningBalance',
        description: 'Recovery Opening Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'OutstandingRecoveryBalance',
        description: 'Outstanding Recovery Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'TotalRecoveryPayments',
        description: 'Total Recovery Payments',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'MailingAddress1',
        description: 'Debtor Mailing Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingAddress2',
        description: 'Debtor Mailing Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingCity',
        description: 'Debtor Mailing City',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingCountry',
        description: 'Debtor Mailing Country',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingState',
        description: 'Debtor Mailing State',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingZip',
        description: 'Debtor Mailing Zip Code',
        isRequired: false,
        key: false,
      },
      {
        name: 'RefinanceAccount',
        description: 'Date customer is paid by employer',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountCreditReported',
        description: 'Is the account currently reported to any credit bureau?',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastStatementDate',
        description: 'Last Statement Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'DateTransferredToCurrentOwner',
        description: 'Date Transferred To Current Owner',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'ContractDate',
        description: 'Contract Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'AutoVIN',
        description: 'Auto VIN',
        isRequired: false,
        key: false,
      },
      {
        name: 'OrigState',
        description: 'Orig State',
        isRequired: false,
        key: false,
      },
      {
        name: 'CaseNumber',
        description: 'Case Number',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Numeric,
      },
      {
        name: 'Chapter',
        description: 'Chapter',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Numeric,
      },
      {
        name: 'DateFiled',
        description: 'Date Filed',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'BarDate',
        description: 'BarDate',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'LoanOriginationState',
        description: 'Loan Origination State',
        isRequired: false,
        key: false,
      },
      {
        name: 'PropertyStreetAddressLine1',
        description: 'Property Street Address Line 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'PropertyCity',
        description: 'Property City',
        isRequired: false,
        key: false,
      },
      {
        name: 'PropertyState',
        description: 'Property State',
        isRequired: false,
        key: false,
      },
      {
        name: 'PropertyZip',
        description: 'Property Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'ForeclosureIndicator',
        description: 'Foreclosure Indicator',
        isRequired: false,
        key: false,
      },
      {
        name: 'FirstPaymentDueDate',
        description: 'First Payment Due Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'AssetType',
        description: 'Account Asset Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'AsOfDate',
        description: 'As Of Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'CreditorAtChargeOff',
        description: 'Creditor At Charge Off',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwnerAtChargeOff',
        description: 'Account Owner At Charge Off',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastCreditor',
        description: 'Last Creditor',
        isRequired: false,
        key: false,
      },
      {
        name: 'MOB',
        description: 'Months On Book',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'Term',
        description: 'Term',
        isRequired: false,
        key: false,
      },
      {
        name: 'DPD',
        description: 'Days Past Due',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Numeric,
      },
      {
        name: 'MonthlyPaymentAmount',
        description: 'Monthly Payment Amount',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'OriginationAmount',
        description: 'Origination Amount',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'ChargedOffFlag',
        description: 'Charged Off Flag',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'PaymentAmtPriorToChargeOff',
        description: 'Payment Amount Prior To Charge Off',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PostChargeOffPrincipalBalance',
        description: 'Post Charge Off Principal Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PostChargeOffInterestBalance',
        description: 'Post Charge Off Interest Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PostChargeOffFeeBalance',
        description: 'Post Charge Off Fee Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'ChargeOffBalance',
        description: 'Charge Off Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'TotalPaymentsSinceChargeOff',
        description: 'Total Payments Since Charge Off',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'LastStatementAmount',
        description: 'Last Statement Amount',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'AttorneyName',
        description: 'Attorney Name',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyEmail',
        description: 'Attorney Email',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyFirm',
        description: 'Attorney Firm',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyAddress',
        description: 'Attorney Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyCity',
        description: 'Attorney City',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyState',
        description: 'Attorney State',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyZip',
        description: 'Attorney Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyPhone',
        description: 'Attorney Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyFax',
        description: 'Attorney Fax',
        isRequired: false,
        key: false,
      },
      {
        name: 'RetainedFlag',
        description: 'Retained Flag',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'Extra',
        description: 'Extra',
        isRequired: false,
        key: false,
      },
      {
        name: 'Reason',
        description: 'Reason',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationFlag',
        description: 'DSC/LegalRepresentationFlag',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalAccountOwner',
        description: 'Original Account Owner',
        isRequired: true,
        key: false,
      },
      {
        name: 'LastAttemptedPaymentDate',
        description: 'Last Attempted Payment Date',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastAttemptedPaymentAmount',
        description: 'Last Attempted Payment Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee1Description',
        description: 'Fee 1 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee2Description',
        description: 'Fee 2 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee3Description',
        description: 'Fee 3 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee4Description',
        description: 'Fee 4 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee5Description',
        description: 'Fee 5 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementAmount',
        description: 'Settlement Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementAgreementNY',
        description: 'Settlement Agreement NY',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'PropertyStreetAddressLine2',
        description: 'Property Street Address Line 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'PreChargeOffPrincipalBalance',
        description: 'Pre-Charge Off Principal Balance',
        isRequired: false,
        key: false,
      },
      {
        name: 'PreChargeOffInterestBalance',
        description: 'Pre-Charge Off Interest Balance',
        isRequired: false,
        key: false,
      },
      {
        name: 'PreChargeOffFeeBalance',
        description: 'Pre-Charge Off Fee Balance',
        isRequired: false,
        key: false,
      },
      {
        name: 'PostChargeOffInterest',
        description: 'Post-Charge Off Interest',
        isRequired: false,
        key: false,
      },
      {
        name: 'PostChargeOffFees',
        description: 'Post-Charge Off Fees',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalCreditsSinceChargeOff',
        description: 'Total Credits Since Charge Off',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalInterestRate',
        description: 'Original Interest Rate',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyRetainedFlag',
        description: 'Attorney Retained Flag',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyFirmName',
        description: 'Attorney Firm Name',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationType',
        description: 'Representation Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationName',
        description: 'Representation Name',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationAddress1',
        description: 'Representation Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationAddress2',
        description: 'Representation Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationCity',
        description: 'Representation City',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationState',
        description: 'Representation State',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationZip',
        description: 'Representation Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone1Type',
        description: 'Representation Phone 1 Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone1Number',
        description: 'Representation Phone 1 Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone2Type',
        description: 'Representation Phone 2 Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone2Number',
        description: 'Representation Phone 2 Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone3Type',
        description: 'Representation Phone 3 Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone3Number',
        description: 'Representation Phone 3 Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationEmail1',
        description: 'Representation Email 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationEmail2',
        description: 'Representation Email 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresenationEmail3',
        description: 'Representation Email 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationDetails',
        description: 'Representation Details',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalMinimumMonthlyPaymentAmount',
        description: 'Original Minimum Monthly Payment Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold1',
        description: 'Date Account Sold 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner2',
        description: 'Account Owner 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold2',
        description: 'Date Account Sold 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner3',
        description: 'Account Owner 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold3',
        description: 'Date Account Sold 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner4',
        description: 'Account Owner 4',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold4',
        description: 'Date Account Sold 4',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner5',
        description: 'Account Owner 5',
        isRequired: false,
        key: false,
      },
    ],
  }
}

export function getUpdateAccountFileMapRepository(): IFileMap {
  return {
    fields: [
      {
        name: 'ECAID',
        description: 'ECAID',
        isRequired: false,
        key: true,
      },
      {
        name: 'Lender',
        description: 'Original Placer',
        isRequired: false,
        key: true,
      },
      {
        name: 'LoanId',
        description: 'Account Identifier In Client System',
        isRequired: false,
        key: true,
      },
      {
        name: 'AccountSecured',
        description: 'AccountSecured',
        isRequired: false,
        key: false,
      },
      {
        name: 'AdditionalAccountNumber1',
        description: 'AdditionalAccountNumber1',
        isRequired: false,
        key: false,
      },
      {
        name: 'AdditionalAccountNumber2',
        description: 'AdditionalAccountNumber2',
        isRequired: false,
        key: false,
      },
      {
        name: 'AdditionalAccountNumber3',
        description: 'AdditionalAccountNumber3',
        isRequired: false,
        key: false,
      },
      {
        name: 'Address1',
        description: 'Debtor Street Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'Address2',
        description: 'Debtor Street Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'City',
        description: 'Debtor City',
        isRequired: false,
        key: false,
      },
      {
        name: 'State',
        description:
          'Debtor State - Two letter abbreviation of any of the 50 states  (i.e. CA, NY, FL, etc)',
        isRequired: false,
        key: false,
      },
      {
        name: 'Zip',
        description: 'Debtor Zip - Characters for Canada Postal Code ',
        isRequired: false,
        key: false,
      },
      {
        name: 'Country',
        description:
          'Debtor Country Country Codes   The 2-letter codes as  supplied by the ISO (International Organization for Standardization)',
        isRequired: false,
        key: false,
      },
      {
        name: 'HomeStatus',
        description:
          'Home Ownership Status - One letter abbreviation for home ownership status - • W – Own - • R – Rent • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'PhnCountryCode',
        description: 'is the international code used to dial ',
        isRequired: false,
        key: false,
      },
      {
        name: 'PhnCityCode',
        description: 'is the local city code',
        isRequired: false,
        key: false,
      },
      {
        name: 'HomePhone',
        description: 'Debtor Home Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'MobilePhone',
        description: 'Debtor Mobile Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'WorkPhone',
        description: 'Debtor Work Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'WorkPhoneExtension',
        description: 'Debtor Work Phone Extension',
        isRequired: false,
        key: false,
      },
      {
        name: 'Email',
        description: 'Debtor Email Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'Employer',
        description: 'Debtor Employer name',
        isRequired: false,
        key: false,
      },
      {
        name: 'JobTitle',
        description: 'Debtor Job Title',
        isRequired: false,
        key: false,
      },
      {
        name: 'EmploymentStartDate',
        description: 'Date Employment Started',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'EmploymentEndDate',
        description: 'Date Employment Ended',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'PayMethod',
        description:
          'How do You Receive your Pay - One letter abbreviation for pay check payment method   • P – Paper - • D – Direct Deposit - • B – Barter -• O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'EmployeeLastPaid',
        description: 'Date Employee was last paid',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'EmployeeSupervisor',
        description: 'name of Employees Supervisor',
        isRequired: false,
        key: false,
      },
      {
        name: 'EmployeeSupervisorPhone',
        description: 'Phone number of Employees Supervisor',
        isRequired: false,
        key: false,
      },
      {
        name: 'PayFrequency',
        description:
          'How Often are You Paid - One letter abbreviation for pay check frequency   • W – Weekly - • B – Biweekly - • S – Semi-Monthly-• M – Monthly - • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'DayOfPay',
        description: 'Date customer is paid by employer',
        isRequired: false,
        key: false,
      },
      {
        name: 'BankAccountType',
        description:
          'Bank Account Type - One letter abbreviation for bank account type   • C – Checking - • S – Savings - • O - Other',
        isRequired: false,
        key: false,
      },
      {
        name: 'Bankname',
        description: 'Debtor Checking Account Bank name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Routing',
        description: 'Debtor Checking Account Routing (ABA) Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'BankAccountNumber',
        description: 'Debtor Bank Account Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastWorkedDate',
        description:
          'Date Account Last Worked Internally - (Date format could be YYYY/MM/DD)',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'Co-BorrowerAddress1',
        description: 'Co-Debtor Street Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerAddress2',
        description: 'Co-Debtor Street Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerCity',
        description: 'Co-Debtor City',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerState',
        description: 'Co-Debtor State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerZip',
        description: 'Co-Debtor Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerCountry',
        description: 'Co-Debtor Country Codes',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerHomeStatus',
        description: 'Home Ownership Status',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerPhnCountryCode',
        description: 'Co-Debtor International Phone Country Code',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerPhnCityCode',
        description: 'Co-Debtor Local City Phone Code',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerHomePhone',
        description: 'Co-Debtor Home Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMobilePhone',
        description: 'Co-Debtor Mobile Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerWorkPhone',
        description: 'Co-Debtor Work Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerWorkPhoneExtension',
        description: 'Co-Debtor Work Phone Extension',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmail',
        description: 'Co-Debtor Email Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployer',
        description: 'Co-Debtor Employer name',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerJobTitle',
        description: 'Co-Debtor Job Title',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerAddress',
        description: 'Co-Debtor Employer Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerCity',
        description: 'Co-Debtor Employer City',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerState',
        description: 'Co-Debtor Employer State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerZip',
        description: 'Co-Debtor Employer Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerEmployerPhone',
        description: 'Co-Debtor Employer Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingAddress1',
        description: 'Co-Debtor Mailing Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingAddress2',
        description: 'Co-Debtor Mailing Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingCity',
        description: 'Co-Debtor City',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingState',
        description: 'Co-Debtor State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingCountry',
        description: 'Co-Debtor State',
        isRequired: false,
        key: false,
      },
      {
        name: 'Co-BorrowerMailingZip',
        description: 'Co-Debtor Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingAddress1',
        description: 'Debtor Mailing Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingAddress2',
        description: 'Debtor Mailing Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingCity',
        description: 'Debtor Mailing City',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingCountry',
        description: 'Debtor Mailing Country',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingState',
        description: 'Debtor Mailing State',
        isRequired: false,
        key: false,
      },
      {
        name: 'MailingZip',
        description: 'Debtor Mailing Zip Code',
        isRequired: false,
        key: false,
      },
      {
        name: 'AsOfDate',
        description: 'As Of Date',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'CreditorAtChargeOff',
        description: 'Creditor At Charge Off',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwnerAtChargeOff',
        description: 'Account Owner At Charge Off',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastCreditor',
        description: 'Last Creditor',
        isRequired: false,
        key: false,
      },
      {
        name: 'MOB',
        description: 'Months On Book',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Date,
      },
      {
        name: 'Term',
        description: 'Term',
        isRequired: false,
        key: false,
      },
      {
        name: 'DPD',
        description: 'Days Past Due',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Numeric,
      },
      {
        name: 'MonthlyPaymentAmount',
        description: 'Monthly Payment Amount',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'OriginationAmount',
        description: 'Origination Amount',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'ChargedOffFlag',
        description: 'Charged Off Flag',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'PaymentAmtPriorToChargeOff',
        description: 'Payment Amount Prior To Charge Off',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PostChargeOffPrincipalBalance',
        description: 'Post Charge Off Principal Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PostChargeOffInterestBalance',
        description: 'Post Charge Off Interest Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'PostChargeOffFeeBalance',
        description: 'Post Charge Off Fee Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'ChargeOffBalance',
        description: 'Charge Off Balance',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'TotalPaymentsSinceChargeOff',
        description: 'Total Payments Since Charge Off',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'LastStatementAmount',
        description: 'Last Statement Amount',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Currency,
      },
      {
        name: 'AttorneyName',
        description: 'Attorney Name',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyEmail',
        description: 'Attorney Email',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyFirm',
        description: 'Attorney Firm',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyAddress',
        description: 'Attorney Address',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyCity',
        description: 'Attorney City',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyState',
        description: 'Attorney State',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyZip',
        description: 'Attorney Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyPhone',
        description: 'Attorney Phone',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyFax',
        description: 'Attorney Fax',
        isRequired: false,
        key: false,
      },
      {
        name: 'RetainedFlag',
        description: 'Retained Flag',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'Extra',
        description: 'Extra',
        isRequired: false,
        key: false,
      },
      {
        name: 'Reason',
        description: 'Reason',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationFlag',
        description: 'DSC/LegalRepresentationFlag',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalAccountOwner',
        description: 'Original Account Owner',
        isRequired: true,
        key: false,
      },
      {
        name: 'LastAttemptedPaymentDate',
        description: 'Last Attempted Payment Date',
        isRequired: false,
        key: false,
      },
      {
        name: 'LastAttemptedPaymentAmount',
        description: 'Last Attempted Payment Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee1Description',
        description: 'Fee 1 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee2Description',
        description: 'Fee 2 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee3Description',
        description: 'Fee 3 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee4Description',
        description: 'Fee 4 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'Fee5Description',
        description: 'Fee 5 Description',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementAmount',
        description: 'Settlement Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'SettlementAgreementNY',
        description: 'Settlement Agreement NY',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'PropertyStreetAddressLine1',
        description: 'Property Street Address Line 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'PropertyStreetAddressLine2',
        description: 'Property Street Address Line 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'PreChargeOffPrincipalBalance',
        description: 'Pre-Charge Off Principal Balance',
        isRequired: false,
        key: false,
      },
      {
        name: 'PreChargeOffInterestBalance',
        description: 'Pre-Charge Off Interest Balance',
        isRequired: false,
        key: false,
      },
      {
        name: 'PreChargeOffFeeBalance',
        description: 'Pre-Charge Off Fee Balance',
        isRequired: false,
        key: false,
      },
      {
        name: 'PostChargeOffInterest',
        description: 'Post-Charge Off Interest',
        isRequired: false,
        key: false,
      },
      {
        name: 'PostChargeOffFees',
        description: 'Post-Charge Off Fees',
        isRequired: false,
        key: false,
      },
      {
        name: 'TotalCreditsSinceChargeOff',
        description: 'Total Credits Since Charge Off',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalInterestRate',
        description: 'Original Interest Rate',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyRetainedFlag',
        description: 'Attorney Retained Flag',
        isRequired: false,
        key: false,
      },
      {
        name: 'AttorneyFirmName',
        description: 'Attorney Firm Name',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationType',
        description: 'Representation Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationName',
        description: 'Representation Name',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationAddress1',
        description: 'Representation Address 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationAddress2',
        description: 'Representation Address 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationCity',
        description: 'Representation City',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationState',
        description: 'Representation State',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationZip',
        description: 'Representation Zip',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone1Type',
        description: 'Representation Phone 1 Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone1Number',
        description: 'Representation Phone 1 Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone2Type',
        description: 'Representation Phone 2 Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone2Number',
        description: 'Representation Phone 2 Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone3Type',
        description: 'Representation Phone 3 Type',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationPhone3Number',
        description: 'Representation Phone 3 Number',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationEmail1',
        description: 'Representation Email 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationEmail2',
        description: 'Representation Email 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresenationEmail3',
        description: 'Representation Email 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'RepresentationDetails',
        description: 'Representation Details',
        isRequired: false,
        key: false,
      },
      {
        name: 'OriginalMinimumMonthlyPaymentAmount',
        description: 'Original Minimum Monthly Payment Amount',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold1',
        description: 'Date Account Sold 1',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner2',
        description: 'Account Owner 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold2',
        description: 'Date Account Sold 2',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner3',
        description: 'Account Owner 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold3',
        description: 'Date Account Sold 3',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner4',
        description: 'Account Owner 4',
        isRequired: false,
        key: false,
      },
      {
        name: 'DateAccountSold4',
        description: 'Date Account Sold 4',
        isRequired: false,
        key: false,
      },
      {
        name: 'AccountOwner5',
        description: 'Account Owner 5',
        isRequired: false,
        key: false,
      },
    ],
  }
}

export function getChargeOffAccountFileMapRepository(): IFileMap {
  return {
    fields: [
      {
        name: 'ECAID',
        description: 'ECAID',
        isRequired: false,
        key: true,
      },
      {
        name: 'Lender',
        description: 'Original Placer',
        isRequired: false,
        key: true,
      },
      {
        name: 'LoanId',
        description: 'Account Identifier In Client System',
        isRequired: false,
        key: true,
      },
      {
        name: 'ChargeOffAccount',
        description: 'If Yes then the Account is Charged Off ',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'AddInterestBalancetoPostChargeOffPrincipal',
        description:
          'If yes then the InterestBalance column will be added to the PrincipalBalance Column, and the InterestBalance Column will update to $0.',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'ZeroOutInterestBalanceAtChargeOff',
        description:
          'If yes then the InterestBalance Column will update to $0, and not be added to the Principal Balance ',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'AddFeeBalanceToPostChargeOffPrincipal',
        description:
          'If yes then the FeeBalance column will be added to the PrincipalBalance Column, and the FeeBalance Column will update to $0.  ',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
      {
        name: 'ZeroOutFeeBalanceAtChargeOff',
        description:
          'If yes then the FeeBalance Column will update to $0, and not be added to the Principal Balance ',
        isRequired: false,
        key: false,
        type: TypeFieldEnum.Boolean,
      },
    ],
  }
}
