import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import {
  PostPlacementAcceptPostPlacement,
  PostPlacementAddComment,
  PostPlacementBulkRespond,
  PostPlacementCreateRequest,
  PostPlacementRejectPostPlacement,
  PostPlacementSubmitPostPlacement,
  PostPlacementWithdrawPostPlacement,
} from './urls'

export class PostPlacementPostData implements IPostPlacementPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async uploadBulkFile(
    requestType: number | undefined,
    data: any[]
  ): Promise<any> {
    return this.httpClient.post(PostPlacementCreateRequest, {
      requestType: Number(requestType),
      data,
    })
  }

  async addComment(requestId: number, comment: string): Promise<boolean> {
    return this.httpClient.post(PostPlacementAddComment, {
      requestId: Number(requestId),
      comment,
    })
  }

  async acceptPostPlacement(
    requestId: number,
    requestType: number | undefined
  ): Promise<boolean> {
    return this.httpClient.post(PostPlacementAcceptPostPlacement, {
      requestId: Number(requestId),
      requestType,
    })
  }

  async submitPostPlacement(
    requestId: number,
    requestType: number | undefined
  ): Promise<boolean> {
    return this.httpClient.post(PostPlacementSubmitPostPlacement, {
      requestId: Number(requestId),
      requestType,
    })
  }

  async rejectPostPlacement(
    requestId: number,
    reason: string,
    requestType: number | undefined
  ): Promise<boolean> {
    return this.httpClient.post(PostPlacementRejectPostPlacement, {
      requestId: Number(requestId),
      reason,
      requestType,
    })
  }

  async withdrawPostPlacement(
    requestId: number,
    reason: string
  ): Promise<boolean> {
    return this.httpClient.post(PostPlacementWithdrawPostPlacement, {
      requestId: Number(requestId),
      reason,
    })
  }

  async uploadBulkRespond(
    requestType: number | undefined,
    data: any[],
    operation: string
  ): Promise<any> {
    return this.httpClient.post(PostPlacementBulkRespond, {
      requestType: Number(requestType),
      data,
      operation,
    })
  }
}
