/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { AbilityContext } from 'src/context/Can'
import React, { useContext, useMemo, useState } from 'react'
import Vendors from './components/Vendors'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import LoadActions from './components/LoadActions'
import {
  Button,
  DataTable,
  DataTableState,
  SwitchType,
  headerActions,
  renderCellTableActions,
  renderDownloadInfo,
  renderDate,
  renderDateTime,
  renderCurrency,
  Group,
  Flex,
  Content,
  Checkbox,
  Grid,
  Typography,
  Box,
  ButtonDropdown,
  ButtonDropdownItem,
  Colors,
  ModalDialog,
  Step,
  UploadDragDrop,
  fParseXlsxFile,
  WarningToast,
  SuccessToast,
  Dropdown,
  Header,
} from 'everchain-uilibrary'
import FilesFilters from './components/FileTypesFilters'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import DateAccountFilter from './components/DateAccountFilters'
import DateFileFilter from './components/DateFileFilters'
import { dateThreeMonthsPast } from 'src/utils/date'
import { Account } from 'src/domain/models/accounts'
import { DownloadFileUri } from 'src/utils/helper'
import { useHistory } from 'react-router-dom'
import {
  ACCOUNT_DETAIL,
  CHARGE_OFF_STRATEGY_ACCOUNT_FILE_VALIDATION,
} from 'src/presentation/routes'

import {
  profileCountry,
  useGetBusinessessId,
  useIsMultipleBussines,
} from 'src/utils/user'
import { AuthContext } from 'src/context/AuthenticationContext'
import { setBusinessType } from 'src/context/OldAuthenticationContext'

import { FileData } from 'src/domain/models/file'
import { getStandardUri, Options } from 'src/utils/common'
import { TdNoEllipsis } from 'src/presentation/styles/layout'
import { downloadBase64File } from 'src/utils/file/fileDownload'
import UploadModal from './MediaUpload/UploadModal'
import { useCustomQuery } from 'src/infra/reactQuery'
import DownloadModal from './MediaUpload/DownloadModal'
import Strategies from './components/Strategies'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import Placers from './components/Placers'
import {
  actionsAfterChargeOff,
  ChargeOffBalanceEnum,
  ChargeOffTypeEnum,
  FileTypeEnum,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import { useMutation } from '@tanstack/react-query'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { useRecoverGridFilter } from 'src/context/RecoverGridFilterContext'

interface AccountsParams {
  accountGetOperations?: IAccountGetOperations
  accountPostOperations?: IAccountPostOperations
  businessOperations?: IBusinessGetOperations
  fileOperations?: IFileGetOperations
  strategyOperations?: IStrategyGetOperations
}

export interface FormFilter {
  creditorId: string | undefined
  vendorId: string | undefined
  fileType: number | undefined
  dateAccountFrom?: Date
  dateAccountTo?: Date
  dateFileFrom?: Date
  dateFileTo?: Date
  strategyId?: string | undefined
}

interface ChargeOffBalance {
  addInterestBalancetoPostChargeOffPrincipal: boolean
  zeroOutInterestBalanceAtChargeOff: boolean
  addFeeBalanceToPostChargeOffPrincipal: boolean
  zeroOutFeeBalanceAtChargeOff: boolean
}

const Accounts: React.FC<AccountsParams> = ({
  businessOperations,
  accountGetOperations,
  accountPostOperations,
  fileOperations,
  strategyOperations,
}) => {
  const [switchChecked, setSwitchChecked] = useState<any>(false)
  const isMultipleBussines = useIsMultipleBussines()
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const { placerFilter, setPlacerFilter } = useRecoverGridFilter()
  const [openMediaUploadModal, setOpenMediaUploadModal] =
    useState<boolean>(false)
  const [openMediaDownloadModal, setOpenMediaDownloadModal] =
    useState<boolean>(false)

  const [totalFiles, setTotalFiles] = useState<number>(0)
  const [totalAccounts, setTotalAccounts] = useState<number>()
  const [filesData, setFilesData] = useState<FileData[]>()
  const [accounts, setAccounts] = useState<Account[] | undefined>()
  const [form, setForm] = useState<FormFilter | undefined>()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [execQuery, setExecQuery] = useState<boolean>(false)
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [openChargeOffUploadModal, setOpenChargeOffUploadModal] =
    useState<boolean>(false)
  const [openChargeOffConfirmationModal, setOpenChargeOffConfirmationModal] =
    useState<boolean>(false)
  const [typeUpload, setTypeUpload] = useState<string>('')
  const [selectedTemplate, setSelectedTemplate] = useState<number>()
  const [files, setFiles] = useState<File[]>([])
  const [chargeOffCreditor, setChargeOffCreditor] = useState<any>()
  const [actionAfterChargeOff, setActionAfterChargeOff] = useState<any>()
  const [selectedStrategy, setSelectedStrategy] = useState<any>()
  const businessIds = useGetBusinessessId()
  const ability = useContext(AbilityContext)

  const downloadTemplate = async (templateType: number) => {
    try {
      setSelectedTemplate(templateType)
    } catch (err) {
      console.error(err)
    }
  }

  const history = useHistory()
  const { userPermissions, isVendor, isCreditor, dispatch } =
    useContext(AuthContext)

  const handleSwitchChange = (isChecked: boolean) => {
    const newSelectedType = isChecked ? 'vendor' : 'creditor'
    const setBusinessTypeAction = setBusinessType(dispatch)
    setBusinessTypeAction(newSelectedType)
    setFilesData([])
    setAccounts([])
    setGridState({ ...gridState, skip: 0, take: 25 })
    setTotalAccounts(0)
    setTotalFiles(0)
    setForm((prevObj: any) => {
      return { ...prevObj, fileType: undefined }
    })
    window.localStorage.removeItem('accountsFilterStorage')
    window.localStorage.removeItem('filesFilterStorage')
  }

  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const {
    isFetching: fetchingAccounts,
    isLoading: loadingAccounts,
    refetch: refetchAccounts,
  } = useCustomQuery(
    ['getAccounts', gridState, execQuery],
    async () =>
      accountGetOperations
        ?.getAccounts(
          gridState,
          form?.creditorId,
          form?.vendorId,
          form?.dateAccountFrom ?? dateThreeMonthsPast().toISOString(),
          form?.dateAccountTo ?? new Date().toISOString(),
          form?.strategyId
        )
        .then((dataResponse) => {
          setAccounts(dataResponse?.data)
          setTotalAccounts(dataResponse?.totalCount ?? 0)
          setLoadingData(false)
          window.localStorage.setItem(
            'accountsFiltered',
            JSON.stringify({
              accounts: dataResponse?.data,
              totalCount: dataResponse?.totalCount,
            })
          )
        }),
    {
      cacheTime: 0,
      enabled: isCreditor
        ? form?.creditorId !== undefined
        : isVendor
        ? form?.vendorId !== undefined
        : true,
    }
  )

  const fetchFiles = async (gridParams: DataTableState) => {
    try {
      setLoadingData(true)

      const params = {
        gridParams: gridParams,
        creditorId: form?.creditorId,
        vendorId: form?.vendorId,
        dateFrom: form?.dateFileFrom ?? dateThreeMonthsPast().toISOString(),
        dateTo: form?.dateFileTo ?? new Date().toISOString(),
        fileType: form?.fileType,
      }

      const dataResponse = await fileOperations?.getFileData(
        params.gridParams,
        params.creditorId,
        params.vendorId,
        params.dateFrom,
        params.dateTo,
        params.fileType
      )
      setFilesData(dataResponse?.data)
      setTotalFiles(dataResponse?.totalCount ?? 0)
      setLoadingData(false)
    } catch (err) {
      console.log(err)
      setLoadingData(false)
    }
  }
  const exportAccouts = async (exportSelected: boolean) => {
    try {
      setLoadingData(true)
      const dataResponse = await accountGetOperations?.getAccountsFile(
        exportSelected ? accountsSelected : [],
        form?.creditorId,
        form?.vendorId,
        form?.dateAccountFrom ?? dateThreeMonthsPast().toISOString(),
        form?.dateAccountTo ?? new Date().toISOString(),
        form?.strategyId
      )
      downloadBase64File(dataResponse)
      setLoadingData(false)
    } catch (err) {
      console.log(err)
      setLoadingData(false)
    }
  }

  const { isFetching: loadingTemplate } = useCustomQuery(
    ['getTemplateFile', selectedTemplate],
    async () =>
      fileOperations?.getTemplateFile(selectedTemplate).then((template) => {
        setSelectedTemplate(undefined)
        generateFileBlob(template)
      }),
    { cacheTime: 0, enabled: !!selectedTemplate }
  )

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['ecaid']) !==
      undefined
    )
      return true

    return false
  }

  const cleanItems = () => {
    setFiles([])
    setActionAfterChargeOff(0)
    setSelectedStrategy(undefined)
    setFormChargeOffBalances({
      addInterestBalancetoPostChargeOffPrincipal: false,
      zeroOutInterestBalanceAtChargeOff: false,
      addFeeBalanceToPostChargeOffPrincipal: false,
      zeroOutFeeBalanceAtChargeOff: false,
    })
    setChargeOffCreditor(null)
  }

  const closeModal = () => {
    cleanItems()
    setLoadingData(false)
    setOpenChargeOffUploadModal(false)
    setOpenChargeOffConfirmationModal(false)
  }
  const mutationChargeOffAccounts = useMutation({
    mutationFn: async () => {
      const chargeOffOptions = {
        chargeOffAccount: true,
        addInterestBalancetoPostChargeOffPrincipal:
          formChargeOffBalances.addInterestBalancetoPostChargeOffPrincipal.toString(),
        zeroOutInterestBalanceAtChargeOff:
          formChargeOffBalances.zeroOutInterestBalanceAtChargeOff.toString(),
        addFeeBalanceToPostChargeOffPrincipal:
          formChargeOffBalances.addFeeBalanceToPostChargeOffPrincipal.toString(),
        zeroOutFeeBalanceAtChargeOff:
          formChargeOffBalances.zeroOutFeeBalanceAtChargeOff.toString(),
        actionAfterChargeOff: actionAfterChargeOff,
        strategyId: selectedStrategy,
      }

      return accountPostOperations?.processChargeOffAccounts(
        chargeOffCreditor ?? form?.creditorId,
        false,
        typeUpload === 'allAccounts' ? [] : accountsSelected,
        files[0] ?? null,
        {
          creditorId: form?.creditorId,
          vendorId: form?.vendorId,
          dateFrom:
            form?.dateAccountFrom ?? dateThreeMonthsPast().toISOString(),
          dateTo: form?.dateAccountTo ?? new Date().toISOString(),
          strategyId: form?.strategyId,
        },
        chargeOffOptions
      )
    },
    onSuccess: async (response: any) => {
      if (response.data.length > 0) {
        WarningToast(response.data[0].errorMessage)
      } else {
        SuccessToast('Accounts successfully charged-off')
      }
      refetchAccounts()
      setAccountsSelected([])
      closeModal()
    },
    onError: async (response: any) => {
      closeModal()
    },
  })

  const {
    data: strategiesByChargeOffTypeAndBusinessIdsData,
    isFetching: isLoadingStrategiesByChargeOffTypeAndBusinessIds,
  } = useCustomQuery(
    ['strategiesByChargeOffTypeAndBusinessIds'],
    async () =>
      strategyOperations?.getStrategiesByChargeOffTypeAndBusinessIds(
        businessIds,
        ChargeOffTypeEnum.PostChargeOff
      ),
    { cacheTime: 0, enabled: !!actionAfterChargeOff }
  )

  const renderDownloadInfoColumns = () => {
    return [
      { field: 'userName', title: 'User Name', width: 75 },
      {
        field: 'downloadedAt',
        title: 'Downloaded At',
        width: 75,
        render: renderDateTime,
      },
    ]
  }

  const handleCheckSwitch = (value: any) => {
    setFilesData([])
    setAccounts([])
    setGridState({ ...gridState, skip: 0, take: 25 })
    setTotalAccounts(0)
    setTotalFiles(0)
    setSwitchChecked(value)
  }

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleVendorsUpdate = (vendor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, vendorId: vendor }
    })
  }

  const handleStrategiesUpdate = (strategy: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, strategyId: strategy }
    })
  }

  const handleFileTypeUpdate = (fileTypeValue: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, fileType: fileTypeValue }
    })
  }

  const onDateFromAccountFilterUpdate = (dateAccountFrom: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateAccountFrom: dateAccountFrom.toISOString() }
    })
  }

  const onDateToAccountFilterUpdate = (dateAccountTo: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateAccountTo: dateAccountTo.toISOString() }
    })
  }

  const onDateFromFileFilterUpdate = (dateFileFrom: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateFileFrom: dateFileFrom.toISOString() }
    })
  }

  const onDateToFileFilterUpdate = (dateFileTo: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, dateFileTo: dateFileTo.toISOString() }
    })
  }

  const getTitleName = () => {
    return switchChecked ? 'Files' : 'Accounts in Strategy'
  }

  const getColumns = () => {
    return switchChecked ? GridFileColumns() : GridAccountsColumns()
  }

  const handleBulkMediaUpload = () => {
    setOpenMediaUploadModal(true)
  }

  const handleBulkMediaDownload = () => {
    setOpenMediaDownloadModal(true)
  }

  const handleFilter = () => {
    setPlacerFilter(form?.creditorId)
    if (switchChecked) {
      if (form)
        window.localStorage.setItem('filesFilterStorage', JSON.stringify(form))
      fetchFiles(gridState)
    } else {
      if (form)
        window.localStorage.setItem(
          'accountsFilterStorage',
          JSON.stringify(form)
        )

      setGridState({ ...gridState, skip: 0, take: 25 })
      setExecQuery(!execQuery)
      setAccountsSelected([])
    }
  }

  const handleRowClick = (event: any) => {
    history.push(getStandardUri(`${ACCOUNT_DETAIL}/${event.dataItem.ecaid}`))
  }

  const [formChargeOffBalances, setFormChargeOffBalances] =
    useState<ChargeOffBalance>({
      addInterestBalancetoPostChargeOffPrincipal: false,
      zeroOutInterestBalanceAtChargeOff: false,
      addFeeBalanceToPostChargeOffPrincipal: false,
      zeroOutFeeBalanceAtChargeOff: false,
    })

  const handleSwitchCheck = (value: any, type: ChargeOffBalanceEnum) => {
    setFormChargeOffBalances((prevObj: any) => {
      return { ...prevObj, [type]: value }
    })

    if (
      type === ChargeOffBalanceEnum.AddInterestBalancetoPostChargeOffPrincipal
    ) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.ZeroOutInterestBalanceAtChargeOff]: false,
        }
      })
    } else if (
      type === ChargeOffBalanceEnum.ZeroOutInterestBalanceAtChargeOff
    ) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.AddInterestBalancetoPostChargeOffPrincipal]:
            false,
        }
      })
    } else if (
      type === ChargeOffBalanceEnum.AddFeeBalanceToPostChargeOffPrincipal
    ) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.ZeroOutFeeBalanceAtChargeOff]: false,
        }
      })
    } else if (type === ChargeOffBalanceEnum.ZeroOutFeeBalanceAtChargeOff) {
      setFormChargeOffBalances((prevObj: any) => {
        return {
          ...prevObj,
          [ChargeOffBalanceEnum.AddFeeBalanceToPostChargeOffPrincipal]: false,
        }
      })
    }
  }

  const handleCreditorsUpdateChargeOff = (creditor: any) => {
    setChargeOffCreditor(creditor)
  }

  const getModalTitle = () => {
    return typeUpload === 'filteredAccounts'
      ? 'Charge-Off Selected Accounts Confimation'
      : 'Charge-Off All Accounts Confimation'
  }

  const getStrategiesDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.name,
        value: item.id,
      })
    )
    return newOptions
  }

  const strategiesOptions = getStrategiesDropDownOptions(
    strategiesByChargeOffTypeAndBusinessIdsData || []
  )

  const canChargeOff =
    ability.can(
      PermissionCodeAccess.Recover_ManuallyChargeOffAccounts,
      'any'
    ) && !isInternal

  const GridAccountsColumns = () => {
    return [
      {
        title: 'Select',
        width: 80,
        show: true,
        notFilterable: true,
        render: (props: any) => {
          return (
            <TdNoEllipsis>
              <Checkbox
                onChange={(event: any) => {
                  if (event) {
                    setAccountsSelected([
                      ...accountsSelected,
                      props.dataItem['ecaid'],
                    ])
                  } else {
                    setAccountsSelected(
                      accountsSelected.filter(
                        (id) => id !== props.dataItem['ecaid']
                      )
                    )
                  }
                }}
                checked={handleAddCheck(props)}
              />
            </TdNoEllipsis>
          )
        },
      },
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 200,
      },
      {
        field: 'firstName',
        title: 'First Name',
        width: 150,
        show: !isInternal,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        width: 150,
        show: !isInternal,
      },
      { field: 'lender', title: 'Lender', show: true, width: 150 },
      { field: 'lastActivity', title: 'Last Activity', show: true, width: 130 },
      { field: 'accountStatus', title: 'Status', show: true, width: 180 },
      {
        field: 'originalLoanAmount',
        title: 'Orig. Amt.',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'principalBalance',
        title: 'Principal',
        show: true,
        width: 110,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'interestBalance',
        title: 'Interest',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'otherFeesBalances',
        title: 'Fee',
        show: true,
        width: 95,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'lastPaymentAmount',
        title: 'Amount',
        show: true,
        width: 100,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'totalBalance',
        title: 'Total Balance',
        show: true,
        width: 135,
        filter: 'numeric',
        render: (props: any) => renderCurrency(props, profileCountry()),
      },
      {
        field: 'originalDate',
        title: 'Origination Date',
        show: true,
        width: 150,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'defaultDate',
        title: 'Default Date',
        show: true,
        width: 130,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'lastPaymentDate',
        title: 'Last Payment Date',
        show: true,
        width: 165,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'writeOffDate',
        title: 'C/O Date',
        show: true,
        width: 110,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'strategyName',
        title: 'Strategy',
        show: true,
        width: 200,
      },
      {
        field: 'strategyStartDate',
        title: 'Strategy Started At',
        show: true,
        width: 165,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'stageName',
        title: 'Current Stage',
        show: true,
        width: 160,
      },
      {
        field: 'stageStartDate',
        title: 'Stage Started At',
        show: true,
        width: 165,
        render: (props: any) => renderDate(props),
        filter: 'date',
      },
      {
        field: 'placerName',
        title: 'Placer',
        show: true,
        width: 200,
      },
      {
        field: 'ecaid',
        title: 'ECAID',
        show: true,
        width: 250,
      },
    ]
  }

  const GridFileColumns = () => {
    return [
      { field: 'id', title: 'ID', show: false, width: 150 },
      { field: 'fileName', title: 'File Name', show: true, width: 100 },
      {
        field: 'creditor',
        title: 'Placer',
        show: true,
        width: 100,
        render: (props: any) => {
          return (
            <td>{props.dataItem.creditor ? props.dataItem.creditor : 'N/A'}</td>
          )
        },
      },
      {
        field: 'vendor',
        title: 'Vendor',
        show: true,
        width: 100,
        render: (props: any) => {
          return (
            <td>{props.dataItem.vendor ? props.dataItem.vendor : 'N/A'}</td>
          )
        },
      },
      { field: 'fileType', title: 'File Type', show: true, width: 100 },
      {
        field: 'dateCreated',
        title: 'Date Created',
        show: true,
        width: 100,
        render: renderDateTime,
        filter: 'date',
      },
      { field: 'strategyUsed', title: 'Strategy Used', show: true, width: 100 },
      { field: 'status', title: 'Status', show: true, width: 80 },
      {
        field: 'downloadCount',
        title: 'Download Count',
        show: true,
        width: 100,
        render: (props: any) =>
          renderDownloadInfo(
            props.dataItem.downloadHistory.historyDetails,
            renderDownloadInfoColumns(),
            props.dataItem.downloadHistory.count,
            150
          ),
        filter: 'numeric',
      },
      {
        title: 'Actions',
        render: (props: any) =>
          renderCellTableActions(props, actionsMenu(props)),
        headerCell: headerActions,
        show: true,
        width: 30,
      },
    ]
  }

  const actionsMenu = (props: any) => [
    {
      name: 'Download',
      onClick: () => {
        fileOperations
          ?.getFileUri(props.dataItem.id)
          .then((response: any) => {
            DownloadFileUri(response)
            if (switchChecked) fetchFiles(gridState)
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loading: false,
    },
    {
      name: 'Delete',
      onClick: (tableCell: any) => {},
      loading: false,
    },
  ]

  useMemo(() => {
    handleCreditorsUpdate(placerFilter)
    if (
      window.localStorage.getItem('accountsFilterStorage') !== null ||
      window.localStorage.getItem('filesFilterStorage') !== null
    ) {
      setForm(
        switchChecked
          ? JSON.parse(
              window.localStorage.getItem('filesFilterStorage') || ''
            ) || ''
          : JSON.parse(
              window.localStorage.getItem('accountsFilterStorage') ?? ''
            ) || ''
      )
    }

    if (window.localStorage.getItem('accountsFiltered') !== null) {
      setTotalAccounts(
        JSON.parse(window.localStorage.getItem('accountsFiltered') ?? '')
          ?.totalCount
      )
      setAccounts(
        JSON.parse(window.localStorage.getItem('accountsFiltered') ?? '')
          ?.accounts
      )
    }
  }, [placerFilter])
  return (
    <Content id="accounts">
      <Flex justifyContent="space-between" paddingBottom={4}>
        <Header title={getTitleName()} />
        <Group>
          <LoadActions fileOperations={fileOperations}></LoadActions>
          <>
            {isMultipleBussines && (
              <SwitchType
                id="isVendorCheck"
                checked={isVendor}
                onChange={(e: { target: { checked: boolean } }) => {
                  handleCreditorsUpdate(null)
                  handleVendorsUpdate(null)
                  handleFileTypeUpdate(undefined)
                  handleSwitchChange(e.target.checked)
                }}
                variant="primary"
                primary="Placer"
                secondary="Vendor"
              ></SwitchType>
            )}
          </>
          <SwitchType
            id="fileTypeCheck"
            checked={switchChecked}
            onChange={(e: { target: { checked: any } }) =>
              handleCheckSwitch(e.target.checked)
            }
            variant="primary"
            primary="Accounts"
            secondary="Files"
          />
        </Group>
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Group>
          <>
            {(isInternal || isCreditor) && (
              <Placers
                businessOperations={businessOperations}
                onPlacersUpdate={handleCreditorsUpdate}
                selectedPlacer={form?.creditorId}
              />
            )}
            {(isInternal || isVendor) && (
              <Vendors
                businessOperations={businessOperations}
                onVendorUpdate={handleVendorsUpdate}
                selectedVendor={form?.vendorId}
              />
            )}
            {!switchChecked && (
              <Strategies
                strategyOperations={strategyOperations}
                onStrategiesUpdate={handleStrategiesUpdate}
                selectedStrategy={form?.strategyId}
              />
            )}
          </>

          {switchChecked && (
            <FilesFilters
              fileOperations={fileOperations}
              onFileTypeUpdate={handleFileTypeUpdate}
              selectedFileType={form?.fileType}
              form={form}
            />
          )}
          {switchChecked ? (
            <DateFileFilter
              onDateFromFilterUpdate={onDateFromFileFilterUpdate}
              onDateToFilterUpdate={onDateToFileFilterUpdate}
              selectedFromDate={form?.dateFileFrom}
              selectedToDate={form?.dateFileTo}
            />
          ) : (
            <DateAccountFilter
              onDateFromFilterUpdate={onDateFromAccountFilterUpdate}
              onDateToFilterUpdate={onDateToAccountFilterUpdate}
              selectedFromDate={form?.dateAccountFrom}
              selectedToDate={form?.dateAccountTo}
            />
          )}
        </Group>
        <Group>
          <Button
            useRipple
            width="180px"
            height={40}
            onClick={handleBulkMediaDownload}
            isLoading={loadingData || loadingAccounts || fetchingAccounts}
            hidden={!isInternal && !isCreditor && form?.vendorId == null}
          >
            Download Bulk Media
          </Button>
          <Button
            useRipple
            width="180px"
            height={40}
            onClick={handleBulkMediaUpload}
            isLoading={loadingData || loadingAccounts || fetchingAccounts}
            hidden={!isInternal && isCreditor && form?.creditorId == null}
          >
            Bulk Media Upload
          </Button>
          <Button
            useRipple
            width={80}
            height={40}
            onClick={handleFilter}
            isLoading={loadingData || loadingAccounts || fetchingAccounts}
            disabled={
              !isInternal &&
              ((isCreditor && form?.creditorId == null) ||
                (isVendor && form?.vendorId == null))
            }
          >
            Filter
          </Button>
        </Group>
      </Flex>

      <Box mt={5}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Box>
            <Typography style={{ marginBottom: 0 }}>
              Total of accounts selected: {accountsSelected.length}
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={(e) => exportAccouts(true)}
              isLoading={loadingData || loadingAccounts || fetchingAccounts}
              disabled={accountsSelected?.length === 0 || switchChecked}
            >
              Download selected accounts
            </Button>
          </Box>
          <Box>
            <Button
              onClick={(e) => exportAccouts(false)}
              isLoading={loadingData || loadingAccounts || fetchingAccounts}
              disabled={accounts?.length === 0 || switchChecked}
            >
              Download all accounts
            </Button>
          </Box>
          {canChargeOff && !isVendor && (
            <Box>
              <ButtonDropdown
                buttontext="Charge-Off Accounts"
                width="210px"
                variant="primary"
                doubleDropDown={true}
                isLoading={loadingData || loadingAccounts || fetchingAccounts}
                disabled={loadingTemplate || switchChecked}
                mainButtonDropdownChildren={
                  <>
                    <ButtonDropdownItem
                      disabled={!accountsSelected.length}
                      onClick={() => {
                        setOpenChargeOffConfirmationModal(true)
                        setTypeUpload('filteredAccounts')
                      }}
                    >
                      Charge-Off selected accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      disabled={!accounts?.length}
                      onClick={() => {
                        setOpenChargeOffConfirmationModal(true)
                        setTypeUpload('allAccounts')
                      }}
                    >
                      Charge-Off all accounts
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() => setOpenChargeOffUploadModal(true)}
                    >
                      Upload accounts file
                    </ButtonDropdownItem>
                    <ButtonDropdownItem
                      onClick={() =>
                        downloadTemplate(FileTypeEnum.ChargeOffAccounts)
                      }
                    >
                      Download Template
                    </ButtonDropdownItem>
                  </>
                }
                enableSideButton={false}
              ></ButtonDropdown>
            </Box>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        {switchChecked ? (
          <DataTable
            isLoading={loadingData || loadingAccounts || fetchingAccounts}
            height="100%"
            maxHeight="100%"
            gridColumns={getColumns() || []}
            gridState={gridState}
            data={filesData}
            pageable={true}
            total={totalFiles}
            onDataStateChange={(e: { dataState: any }) => {
              setGridState(e.dataState)
              if (filesData) {
                fetchFiles(e.dataState)
              }
            }}
          />
        ) : (
          <DataTable
            sortable={true}
            useFilterMenu={true}
            isLoading={loadingData || loadingAccounts || fetchingAccounts}
            height="100%"
            maxHeight="100%"
            gridColumns={getColumns() || []}
            gridState={gridState}
            data={accounts}
            pageable={true}
            total={totalAccounts}
            onRowClick={handleRowClick}
            onDataStateChange={(e: { dataState: any }) => {
              setGridState(e.dataState)
            }}
          />
        )}
      </Box>
      <UploadModal
        open={openMediaUploadModal}
        setOpenUploadModal={setOpenMediaUploadModal}
      />
      <DownloadModal
        open={openMediaDownloadModal}
        setOpenUploadModal={setOpenMediaDownloadModal}
        form={form}
      />
      <ModalDialog
        header={getModalTitle()}
        isOpen={openChargeOffConfirmationModal}
        onClose={() => {
          closeModal()
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !form?.creditorId ||
          loadingData ||
          fetchingAccounts ||
          !actionAfterChargeOff ||
          (actionAfterChargeOff === 2 && !selectedStrategy)
        }
        onContinue={() => {
          setLoadingData(true)
          mutationChargeOffAccounts.mutate()
        }}
        isFetching={mutationChargeOffAccounts.isLoading}
        isLoading={mutationChargeOffAccounts.isLoading}
        maxwidth="600px"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Step
              completed={true}
              title="Select Charge-Off balance adjustments"
              stepNumber="1"
            >
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Add Interest Balance to Post-Charge-Off Principal?
                </Typography>
                <SwitchType
                  id="AddInterestBalancetoPostChargeOffPrincipal"
                  checked={
                    formChargeOffBalances.addInterestBalancetoPostChargeOffPrincipal
                  }
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.AddInterestBalancetoPostChargeOffPrincipal
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Zero out Interest Balance at Charge-Off?
                </Typography>
                <SwitchType
                  id="ZeroOutInterestBalanceAtChargeOff"
                  checked={
                    formChargeOffBalances.zeroOutInterestBalanceAtChargeOff
                  }
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.ZeroOutInterestBalanceAtChargeOff
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Add Fee Balance to Post-Charge-Off Principal?
                </Typography>
                <SwitchType
                  id="AddFeeBalanceToPostChargeOffPrincipal"
                  checked={
                    formChargeOffBalances.addFeeBalanceToPostChargeOffPrincipal
                  }
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.AddFeeBalanceToPostChargeOffPrincipal
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
              <Box style={{ padding: 10 }}>
                <Typography style={{ padding: 5 }}>
                  Zero out Fee Balance at Charge-Off?
                </Typography>
                <SwitchType
                  id="ZeroOutFeeBalanceAtChargeOff"
                  checked={formChargeOffBalances.zeroOutFeeBalanceAtChargeOff}
                  onChange={(e: { target: { checked: any } }) =>
                    handleSwitchCheck(
                      e.target.checked,
                      ChargeOffBalanceEnum.ZeroOutFeeBalanceAtChargeOff
                    )
                  }
                  variant="primary"
                  primary="No"
                  secondary="Yes"
                />
              </Box>
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Step
              completed={!!actionAfterChargeOff}
              title="Select the Action After Charge-Off"
              stepNumber="2"
            >
              <Dropdown
                width="100%"
                options={actionsAfterChargeOff}
                placeholder="Action After Charge-Off"
                onChange={(props: any) => {
                  setActionAfterChargeOff(props?.value)
                }}
                value={actionAfterChargeOff}
              />
            </Step>
          </Grid>
          {actionAfterChargeOff ===
            actionsAfterChargeOff.find((x) => x.value === 2)?.value && (
            <Grid item xs={12}>
              <Step
                completed={!!selectedStrategy}
                title="Select the Strategy"
                stepNumber="3"
              >
                <Dropdown
                  width="100%"
                  options={strategiesOptions}
                  placeholder="Strategy"
                  onChange={(props: any) => {
                    setSelectedStrategy(props?.value)
                  }}
                  value={selectedStrategy}
                  isLoading={isLoadingStrategiesByChargeOffTypeAndBusinessIds}
                />
              </Step>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              color={Colors.primary}
              variant="caption"
              style={{ fontSize: 18 }}
            >
              {typeUpload === 'filteredAccounts'
                ? `Do you confirm that you want to Charge-Off ${accountsSelected.length} Accounts?`
                : `Do you confirm that you want to Charge-Off ${totalAccounts} Accounts?`}
            </Typography>
          </Grid>
        </Grid>
      </ModalDialog>
      <ModalDialog
        header="Upload Accounts File Confirmation"
        isOpen={openChargeOffUploadModal}
        onClose={() => {
          closeModal()
        }}
        buttonOkText="Confirm"
        buttonCancelText="Cancel"
        disableOkButton={
          !files.length ||
          !chargeOffCreditor ||
          loadingData ||
          fetchingAccounts ||
          !actionAfterChargeOff ||
          (actionAfterChargeOff === 2 && !selectedStrategy)
        }
        onContinue={() => {
          if (files[0]) {
            setLoadingData(true)
            fParseXlsxFile(files[0]).then((result: any) => {
              history.push({
                pathname: getStandardUri(
                  CHARGE_OFF_STRATEGY_ACCOUNT_FILE_VALIDATION
                ),
                state: {
                  creditorId: chargeOffCreditor,
                  fileData: result.data,
                  fileUploaded: files[0],
                  actionAfterChargeOff: actionAfterChargeOff,
                  strategyId: selectedStrategy,
                },
              })
            })
          }
        }}
        isLoading={loadingData}
        isFetching={loadingData}
        maxwidth="450px"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Step
              completed={!!chargeOffCreditor}
              title="Select the business"
              stepNumber="1"
            >
              <Placers
                businessOperations={businessOperations}
                onPlacersUpdate={handleCreditorsUpdateChargeOff}
                selectedPlacer={chargeOffCreditor}
              />
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Step
              completed={!!files.length}
              title="Select the file"
              stepNumber="2"
            >
              <Grid>
                <UploadDragDrop
                  files={files}
                  setFiles={setFiles}
                  hideUploadButton={true}
                />
              </Grid>
            </Step>
          </Grid>
          <Grid item xs={12}>
            <Step
              completed={!!actionAfterChargeOff}
              title="Select the Action After Charge-Off"
              stepNumber="3"
            >
              <Dropdown
                width="100%"
                options={actionsAfterChargeOff}
                placeholder="Action After Charge-Off"
                onChange={(props: any) => {
                  setActionAfterChargeOff(props?.value)
                }}
                value={actionAfterChargeOff}
              />
            </Step>
          </Grid>
          {actionAfterChargeOff ===
            actionsAfterChargeOff.find((x) => x.value === 2)?.value && (
            <Grid item xs={12}>
              <Step
                completed={!!selectedStrategy}
                title="Select the Strategy"
                stepNumber="4"
              >
                <Dropdown
                  width="100%"
                  options={strategiesOptions}
                  placeholder="Strategy"
                  onChange={(props: any) => {
                    setSelectedStrategy(props?.value)
                  }}
                  value={selectedStrategy}
                  isLoading={isLoadingStrategiesByChargeOffTypeAndBusinessIds}
                />
              </Step>
            </Grid>
          )}
        </Grid>
      </ModalDialog>
    </Content>
  )
}

export default Accounts
