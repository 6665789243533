import { ErrorToast, SuccessToast, ValidationResult } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ACCOUNTS } from 'src/presentation/routes'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { UpdateAccountFileData } from 'src/domain/models/accounts'
import { useMutation } from '@tanstack/react-query'
import { FileTypeEnum } from 'src/utils/constants'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import { profileCountry } from 'src/utils/user'
import { transformPropertiesToLowercase } from 'src/utils/file'
import { getStandardUri } from 'src/utils/common'

interface FileValidationParams {
  fileOperations: IFileGetOperations
  accountPostOperations: IAccountPostOperations
}

const UpdateAccountFileValidation: React.FC<FileValidationParams> = ({
  fileOperations,
  accountPostOperations,
}: FileValidationParams) => {
  const location = useLocation()
  const history = useHistory()
  const [processmentErrors, setProcessmentErrors] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)

  window.localStorage.removeItem('filesFilterStorage')
  window.localStorage.removeItem('accountsFilterStorage')
  window.localStorage.removeItem('accountsFiltered')

  const mutationProcessUpdateAccountFile = useMutation({
    mutationFn: async (params: any) => {
      return accountPostOperations.processUpdateAccountFile(
        params.creditorId,
        params.updateAccountFileData,
        params.file
      )
    },
    onSuccess: async (response: any) => {
      setIsFetching(false)
      if (response.data.length > 0) setProcessmentErrors(response.data)
      else {
        history.push(getStandardUri(ACCOUNTS))
        SuccessToast('File successfully submitted')
      }
    },
    onError: async (response: any) => {
      setIsFetching(false)
    },
  })

  const stateData: any = location.state

  if (
    !stateData ||
    !stateData.hasOwnProperty('fileData') ||
    !stateData.hasOwnProperty('creditorId') ||
    !stateData.hasOwnProperty('fileUploaded')
  ) {
    history.push(getStandardUri(ACCOUNTS))
    ErrorToast('Error')
    return <></>
  }

  const fileMap = fileOperations.getFileMap(FileTypeEnum.UpdateAccountFile)
  const { fileData, creditorId, fileUploaded } = stateData

  const mapToAccountFileObject = (input: any): UpdateAccountFileData => {
    const dataRow: UpdateAccountFileData = {
      rowIndex: input.rowindex,
      ecaid: input.ecaid,
      lender: input.lender,
      loanid: input.loanid,
      address1: input.address1,
      address2: input.address2,
      city: input.city,
      state: input.state,
      zip: input.zip,
      country: input.country,
      accountSecured: input.accountsecured,
      additionalAccountNumber1: input.additionalaccountnumber1,
      additionalAccountNumber2: input.additionalaccountnumber2,
      additionalAccountNumber3: input.additionalaccountnumber3,
      homeStatus: input.homestatus,
      phnCountryCode: input.phncountrycode,
      phnCityCode: input.phncitycode,
      homePhone: input.homephone,
      mobilePhone: input.mobilephone,
      workPhone: input.workphone,
      workPhoneExtension: input.workphoneextension,
      email: input.email,
      employer: input.employer,
      jobTitle: input.jobtitle,
      employmentStartDate: input.employmentstartdate,
      employmentEndDate: input.employmentEndDate,
      payMethod: input.paymethod,
      employeeLastPaid: input.employeelastpaid,
      employeeSupervisor: input.employeesupervisor,
      employeeSupervisorPhone: input.employeesupervisorphone,
      payFrequency: input.payfrequency,
      dayOfPay: input.dayofpay,
      bankAccountType: input.bankaccounttype,
      bankName: input.bankname,
      routing: input.routing,
      bankAccountNumber: input.bankaccountnumber,
      lastWorkedDate: input.lastworkeddate,
      coBorrowerAddress1: input['co-borroweraddress1'],
      coBorrowerAddress2: input['co-borroweraddress2'],
      coBorrowerCity: input['co-borrowercity'],
      coBorrowerState: input['co-borrowerstate'],
      coBorrowerZip: input['co-borrowerzip'],
      coBorrowerCountry: input['co-borrowercountry'],
      coBorrowerHomeStatus: input['co-borrowerhomestatus'],
      coBorrowerPhnCountryCode: input['co-borrowerphncountrycode'],
      coBorrowerPhnCityCode: input['co-borrowerphncitycode'],
      coBorrowerHomePhone: input['co-borrowerhomephone'],
      coBorrowerMobilePhone: input['co-borrowermobilephone'],
      coBorrowerWorkPhone: input['co--borrowerworkphone'],
      coBorrowerWorkPhoneExtension: input['co-borrowerworkphoneextension'],
      coBorrowerEmail: input['co-borroweremail'],
      coBorrowerEmployer: input['co-borroweremployer'],
      coBorrowerJobTitle: input['co-borrowerjobtitle'],
      coBorrowerEmployerAddress: input['co-borroweremployeraddress'],
      coBorrowerEmployerCity: input['co-borroweremployercity'],
      coBorrowerEmployerState: input['co-borroweremployerstate'],
      coBorrowerEmployerZip: input['co-borroweremployerzip'],
      coBorrowerEmployerPhone: input['co-borroweremployerphone'],
      coBorrowerMailingAddress1: input['co-borrowermailingaddress1'],
      coBorrowerMailingAddress2: input['co-borrowermailingaddress2'],
      coBorrowerMailingCity: input['co-borrowermailingcity'],
      coBorrowerMailingState: input['co-borrowermailingstate'],
      coBorrowerMailingCountry: input['co-borrowermailingcountry'],
      coBorrowerMailingZip: input['co-borrowermailingzip'],
      mailingAddress1: input.mailingaddress1,
      mailingAddress2: input.mailingaddress2,
      mailingCity: input.mailingcity,
      mailingState: input.mailingstate,
      mailingCountry: input.mailingcountry,
      mailingZip: input.mailingzip,
      totalRecoveryPayments: input.totalrecoverypayments,
      paymentAmtPriorToChargeOff: input.paymentamtpriortochargeoff,
      chargedOffFlag: input.chargedoffflag,
      postChargeOffPrincipalBalance: input.postchargeoffprincipalbalance,
      postChargeOffInterestBalance: input.postchargeoffinterestbalance,
      postChargeOffFeeBalance: input.postchargeofffeebalance,
      chargeOffBalance: input.chargeoffbalance,
      totalPaymentsSinceChargeOff: input.totalpaymentssincechargeoff,
      asOfDate: input.asofdate,
      creditorAtChargeOff: input.creditoratchargeoff,
      accountOwnerAtChargeOff: input.accountowneratchargeoff,
      lastCreditor: input.lastcreditor,
      mob: input.mob,
      term: input.term,
      dpd: input.dpd,
      monthlyPaymentAmount: input.monthlypaymentamount,
      originationAmount: input.originationamount,
      lastStatementAmount: input.laststatementamount,
      attorneyName: input.attorneyname,
      attorneyEmail: input.attorneyemail,
      attorneyFirm: input.attorneyfirm,
      attorneyAddress: input.attorneyaddress,
      attorneyCity: input.attorneycity,
      attorneyState: input.attorneystate,
      attorneyZip: input.attorneyzip,
      attorneyPhone: input.attorneyphone,
      attorneyFax: input.attorneyfax,
      retainedFlag: input.retainedflag,
      extra: input.extra,
      reason: input.reason,
      representationFlag: input.representationflag,
    }

    return dataRow
  }

  const handleConfirmProcessAccountFile = () => {
    setIsFetching(true)
    const updateAccountFileData: UpdateAccountFileData[] = []
    fileData.forEach((x: any) => {
      const loweredCaseObject = transformPropertiesToLowercase(x)
      updateAccountFileData.push(mapToAccountFileObject(loweredCaseObject))
    })

    const params = {
      creditorId,
      updateAccountFileData,
      file: fileUploaded,
    }

    mutationProcessUpdateAccountFile.mutate(params)
  }

  return (
    <>
      <ValidationResult
        fileMap={fileMap}
        fileOptions={{
          formatDate: profileCountry(),
        }}
        fileData={fileData}
        processmentErrors={processmentErrors}
        isFetchingProceed={isFetching}
        onClickCancelButton={() => history.push(getStandardUri(ACCOUNTS))}
        onClickProceedButton={handleConfirmProcessAccountFile}
      />
    </>
  )
}

export default UpdateAccountFileValidation
