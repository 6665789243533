/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useCallback, useContext, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Typography,
  Dropdown,
  DataTableState,
  DataTable,
  Checkbox,
  ErrorToast,
  SuccessToast,
  ModalDialog,
  Icon,
  fParseXlsxFile,
  UploadItem,
  Skeleton,
} from 'everchain-uilibrary'
import { useHistory } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DropUpload } from 'src/presentation/styles/mediaUpload'
import Creditors from '../Accounts/components/Creditors'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import PostPlacementTypes from '../Accounts/components/PostPlacementTypes'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { Account } from 'src/domain/models/accounts'
import { TdNoEllipsis } from 'src/presentation/styles/layout'
import { useMutation } from '@tanstack/react-query'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import { useDropzone } from 'react-dropzone'
import { DropzoneErrorCodes, PostPlacementTypeEnum } from 'src/utils/constants'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import Last4SSNInput from '../Accounts/components/Last4SSNInput'
import AccountIDsInput from '../Accounts/components/AccountIdsInput'
import { BULK_POST_PLACEMENT_RESULT } from 'src/presentation/routes'
import { getStandardUri } from 'src/utils/common'
import Page from '../Common/Page'
import { useRecoverGridFilter } from 'src/context/RecoverGridFilterContext'

interface BulkPostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
}

export interface FormFilter {
  creditorId: string | undefined
  vendorId: string | undefined
  last4SSN: string | undefined
  accountIDs: string | undefined
  postPlacementType: string | undefined
  type: number | undefined
}

interface Options {
  label: string
  value: string
}

const Accounts: React.FC<BulkPostPlacementParams> = ({
  businessOperations,
  postPlacementOperations,
  postPlacementPostOperations,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const history = useHistory()
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const { isVendor, isCreditor } = useContext(AuthContext)
  const operationType = isVendor ? 2 : 1
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [fetchingAccounts, setIsFetchingAccounts] = useState<boolean>(false)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const [fileBulk, setFileBulk] = useState<any[]>([])
  const [processedFile, setProcessedFile] = useState<any[]>([])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [rawData, setRawData] = useState<any[]>([])
  const { placerFilter, setPlacerFilter } = useRecoverGridFilter()

  const MAX_SIZE_FILE = 5368709120 // 5GB

  const getPersistedAccountFilterData = () => {
    if (window.localStorage.getItem('accountsFilterStorage') !== null) {
      return JSON.parse(
        window.localStorage.getItem('accountsFilterStorage') ?? ''
      )
    }
  }

  const onFileBulkDrop = useCallback((acceptedFiles: any) => {
    fParseXlsxFile(acceptedFiles[0]).then((result: any) => {
      if (result.data?.length === 0) {
        ErrorToast('File is empty.')
        return
      }

      const headers = ['ECAID'] as string[]

      const notFound =
        result.data &&
        headers.filter((key) => {
          return !Object.keys(result.data[0]).find(
            (x: any) => x.toLowerCase().trim() === key.toLowerCase().trim()
          )
        })
      if (notFound.length > 0) {
        ErrorToast(
          `Error uploading bulk file. Header(s) not found ${notFound.join(
            ', '
          )}`
        )
        return
      }
      if (!result.passedValidation) {
        ErrorToast(`Error uploading bulk file. ${result.errorMessage}`)
        return
      }
      setFileBulk(acceptedFiles)
      setRawData(result.data)
    })
  }, [])

  const processFile = () => {
    let requests = rawData.map((_item: any) => {
      let metaData = {}

      const isStatusUpdate = ['yes', 'true', '1', 'y'].includes(
        _item.IsStatusUpdate?.toLowerCase()
      )

      switch (form?.type) {
        case PostPlacementTypeEnum.Bankrupt:
          metaData = {
            caseNumber: _item.CaseNumber, // case number *required
            chapter: Number(_item.Chapter), // chapter *required
            fileDate: _item.FileDate, // filedate *required
            attorneyName: _item.AttorneyName, // attorney name
            attorneyPhone: _item.AttorneyPhone, // attorney phone
            district: _item.District, // district
            raw07: _item.InsolvencyType
              ? _item.InsolvencyType.toString()
              : _item.InsolvencyType, // *required
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.Deceased:
          metaData = {
            dateOfDeath: _item.DateOfDeath,
            notificationSource: _item.NotificationSource,
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.Retain:
          metaData = {
            explanation: _item.Explanation,
            numberOfDays: Number(_item.NumberOfDays),
          }
          break
        case PostPlacementTypeEnum.Info:
          metaData = {
            infoType: _item.InfoType,
            infoRequested: _item.InfoRequested,
          }
          break
        case PostPlacementTypeEnum.Legal:
        case PostPlacementTypeEnum.Fraud:
        case PostPlacementTypeEnum.Compliance:
        case PostPlacementTypeEnum.SCRA:
          metaData = {
            type: _item.Type,
            explanation: _item.Explanation,
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.Other:
        case PostPlacementTypeEnum.InaccurateData:
        case PostPlacementTypeEnum.DebtSettlement:
        case PostPlacementTypeEnum.CCCS:
          metaData = {
            explanation: _item.Explanation,
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.PaidPrior:
          metaData = {
            paidPriorDate: _item.PaidPriorDate,
            paidPriorType: _item.PaidPriorType,
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.LowBalance:
          metaData = {
            totalBalanceAtClosure: Number(_item.TotalBalanceAtClosure),
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.OutOfStat:
          metaData = {
            outOfStatuteDate: _item.OutOfStatuteDate,
            outOfStatuteState: _item.OutOfStatuteState,
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.PifSif:
          metaData = {
            pifSifType: _item.PifSifType,
            paymentDate: _item.PaymentDate,
            paymentAmount: Number(_item.PaymentAmount),
            checkOrReferenceNumber: _item.CheckOrReferenceNumber,
            locationOrStore: _item.LocationOrStore,
            consumerAddress: _item.ConsumerAddress,
            consumerCity: _item.ConsumerCity,
            consumerState: _item.ConsumerState,
            consumerPostalCode: _item.ConsumerPostalCode,
            consumerPhoneNumber: _item.ConsumerPhoneNumber,
            isStatusUpdate,
          }
          break
        case PostPlacementTypeEnum.CreditBureauReporting:
          metaData = {
            creditBureauType: _item.CreditBureauType,
            tradelineAdded: _item.TradelineAdded,
            tradelineRemoved: _item.TradelineRemoved,
            explanation: _item.Explanation,
            isStatusUpdate,
          }
          break
      }

      return {
        ECAID: _item.ECAID,
        rowIndex: _item.rowIndex,
        metaData: JSON.stringify(metaData),
        ...metaData,
      }
    })

    setProcessedFile(requests)
  }

  const handleFileBulkRejected = (data: any) => {
    const message =
      data[0].errors[0].code === DropzoneErrorCodes.INVALID_TYPE_FILE
        ? 'Only .csv and .xlsx files are accepted.'
        : data[0].errors[0].code === DropzoneErrorCodes.FILE_TOO_BIG
        ? 'File is over the 5GB limit.'
        : ''
    ErrorToast(`The file has been rejected. ${message}`)
  }

  const {
    getRootProps: getFileBookRootProps,
    getInputProps: geFileBookInputProps,
    isDragActive: isFileBookDragActive,
  } = useDropzone({
    onDrop: onFileBulkDrop,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropRejected: handleFileBulkRejected,
  })

  const [form, setForm] = useState<FormFilter | undefined>(
    getPersistedAccountFilterData()
  )

  const getPersistedAccountData = () => {
    if (window.localStorage.getItem('accountsFiltered') !== null) {
      return JSON.parse(window.localStorage.getItem('accountsFiltered') ?? '')
    }
  }

  const [accounts, setAccounts] = useState<Account[] | undefined>(
    getPersistedAccountData()?.accounts || []
  )

  const [totalAccounts, setTotalAccounts] = useState<number>(
    getPersistedAccountData()?.totalCount | 0
  )

  const { data, isLoading: loadingAgencies } = useCustomQuery(
    ['getPlacedAccounts', form?.creditorId],
    async () =>
      businessOperations?.getPlacedBusiness(
        [form?.creditorId || ''],
        operationType
      ),
    { cacheTime: 0, enabled: !!form?.creditorId }
  )

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const mutationGetTemplate = useMutation({
    mutationFn: async () => {
      return postPlacementOperations?.getTemplateFile(form?.type)
    },
    onSuccess: async (response: any) => {
      generateFileBlob(response)
    },
    onError: async () => {
      ErrorToast('Error downloading template')
    },
  })

  const mutationUploadFile = useMutation({
    mutationFn: async () => {
      setIsProcessing(true)
      return postPlacementPostOperations?.uploadBulkFile(
        form?.type,
        processedFile
      )
    },
    onSuccess: async (response: any) => {
      setShowUploadDialog(false)
      setIsProcessing(false)
      if (response?.data?.isSuccess) {
        SuccessToast('Post Placement Requests created')
      } else {
        ErrorToast('Error processing file')
      }

      history.push({
        pathname: getStandardUri(BULK_POST_PLACEMENT_RESULT),
        state: {
          data: response.data,
          requestTypeId: form?.type,
          allValidAccounts: processedFile,
        },
      })
    },
    onError: async (response: any) => {
      setIsProcessing(false)
      history.push({
        pathname: getStandardUri(BULK_POST_PLACEMENT_RESULT),
        state: {
          data: response.data,
          requestTypeId: form?.type,
          allValidAccounts: processedFile,
        },
      })
    },
  })

  const mutationGetAccounts = useMutation({
    mutationFn: async () => {
      setIsFetchingAccounts(true)
      return postPlacementOperations?.getAccounts(
        gridState,
        form?.creditorId,
        form?.vendorId,
        form?.last4SSN,
        form?.accountIDs
      )
    },
    onSuccess: async (response: any) => {
      setIsFetchingAccounts(false)
      setAccounts(response?.data)
      setTotalAccounts(response?.totalCount ?? 0)
      window.localStorage.setItem(
        'accountsFiltered',
        JSON.stringify({
          accounts: response?.data,
          totalCount: response?.totalCount,
        })
      )
    },
    onError: async (response: any) => {
      setIsFetchingAccounts(false)
    },
  })

  const handleFilterClick = () => {
    setPlacerFilter(form?.creditorId)
    mutationGetAccounts.mutate()
  }

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.businessName,
        value: item.businessId,
      })
    )
    return newOptions
  }

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleTypessUpdate = (type: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, type: Number(type?.split('#')[0]) }
    })
  }

  const handleAgencyUpdate = (vendor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, vendorId: vendor }
    })
  }

  const handleLast4Update = (last4SSN: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, last4SSN: last4SSN.currentTarget.value }
    })
  }

  const handleAccountIDsUpdate = (accountIDs: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, accountIDs: accountIDs.currentTarget.value }
    })
  }

  const handleUploadFile = () => {
    processFile()
    mutationUploadFile.mutate()
  }

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['ecaid']) !==
      undefined
    )
      return true

    return false
  }

  const onHandleOpenUploadDialog = () => setShowUploadDialog(true)

  const options = getDropDownOptions(data?.businessList || [])

  const GridAccountsColumns = () => {
    return [
      {
        title: 'Select',
        width: 80,
        show: true,
        notFilterable: true,
        render: (props: any) => {
          return (
            <TdNoEllipsis>
              <Checkbox
                onChange={(event: any) => {
                  if (event) {
                    setAccountsSelected([
                      ...accountsSelected,
                      props.dataItem['ecaid'],
                    ])
                  } else {
                    setAccountsSelected(
                      accountsSelected.filter(
                        (id) => id !== props.dataItem['ecaid']
                      )
                    )
                  }
                }}
                checked={handleAddCheck(props)}
              />
            </TdNoEllipsis>
          )
        },
      },
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 200,
      },
      {
        field: 'firstName',
        title: 'First Name',
        width: 150,
        show: !isInternal,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        width: 150,
        show: !isInternal,
      },
      { field: 'strategyName', title: 'Strategy', show: true, width: 150 },
      {
        field: 'daysInStrategy',
        title: 'Days in Strategy',
        show: true,
        width: 150,
        filter: 'numeric',
        notFilterable: true,
      },
      {
        field: 'stageName',
        title: 'Stage',
        show: true,
        width: 150,
      },
      {
        field: 'daysInStage',
        title: 'Days in Stage',
        show: true,
        width: 150,
        filter: 'numeric',
        notFilterable: true,
      },
      {
        field: 'ecaid',
        title: 'ECAID',
        show: true,
        width: 250,
        filter: 'text',
      },
    ]
  }

  return (
    <Page id="accounts" title="Create Post Placement Request(s)">
      <Flex justifyContent="space-between">
        <Group>
          <>
            <Creditors
              businessOperations={businessOperations}
              onCreditorsUpdate={handleCreditorsUpdate}
              selectedCreditor={
                placerFilter || getPersistedAccountFilterData()?.creditorId
              }
            />
            <Dropdown
              id="agency-dropdown"
              maxwidth="300px"
              width="300px"
              options={options}
              value={form?.vendorId || undefined}
              onChange={(option?: Options) => {
                handleAgencyUpdate(option?.value)
              }}
              disabled={!form?.creditorId}
              placeholder="Agency"
              isLoading={loadingAgencies && !!form?.creditorId}
            />
            <Last4SSNInput isForm={false} onBlur={handleLast4Update} />
            <AccountIDsInput isForm={false} onBlur={handleAccountIDsUpdate} />
          </>
        </Group>
        <Button
          useRipple
          width={80}
          height={40}
          disabled={
            !isInternal &&
            ((isCreditor && form?.creditorId == null) ||
              (isVendor && form?.vendorId == null))
          }
          onClick={handleFilterClick}
        >
          Filter
        </Button>
      </Flex>
      <Box mt={5}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Box>
            <Typography style={{ marginBottom: 0 }}>
              Total of accounts selected: {accountsSelected.length}
            </Typography>
          </Box>
          <Box>
            <PostPlacementTypes
              businessOperations={businessOperations}
              onTypesUpdate={handleTypessUpdate}
              selectedType={getPersistedAccountFilterData()?.type}
              splitPifSif={true}
            ></PostPlacementTypes>
          </Box>
          <Box>
            <Button
              disabled={!form?.type && form?.type !== 0}
              onClick={() => mutationGetTemplate.mutate()}
            >
              Download Template
            </Button>
          </Box>
          <Box>
            <Button
              onClick={onHandleOpenUploadDialog}
              disabled={!form?.type && form?.type !== 0}
              hidden={isInternal}
            >
              Upload Bulk Request File
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box mt={5}>
        <DataTable
          sortable={true}
          useFilterMenu={true}
          isLoading={fetchingAccounts}
          height="100%"
          maxHeight="100%"
          gridColumns={GridAccountsColumns() || []}
          gridState={gridState}
          data={accounts}
          pageable={true}
          total={totalAccounts}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
            mutationGetAccounts.mutate()
          }}
        />
      </Box>
      <ModalDialog
        isOpen={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        aria-labelledby="form-filter"
        hideOkButton={true}
        hideCancelButton={true}
        header="Upload File"
        width="600px"
      >
        <Skeleton isLoading={isProcessing} width="100%" height="50px">
          <Box display="flex" flexDirection="column">
            <Box>
              {fileBulk.length === 0 ? (
                <>
                  <DropUpload
                    isDragActive={isFileBookDragActive}
                    {...getFileBookRootProps()}
                  >
                    <input {...geFileBookInputProps()} />
                    <div className="upload-placeholder">
                      <Icon name="CloudUpload" className="upload-icon" />{' '}
                      <strong>Choose a Bulk File </strong> or drop a bulk file
                      here to upload
                    </div>
                  </DropUpload>
                </>
              ) : (
                <UploadItem
                  file={fileBulk[0]}
                  removeFile={() => setFileBulk([])}
                ></UploadItem>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt={2}>
              <Button
                onClick={handleUploadFile}
                disabled={fileBulk?.length === 0}
              >
                Upload File
              </Button>
            </Box>
          </Box>
        </Skeleton>
      </ModalDialog>
    </Page>
  )
}

export default Accounts
