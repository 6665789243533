import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import { FileValidationResult } from 'src/domain/models/file'
import { ApiError } from 'src/domain/models/api/api'
import * as types from 'src/domain/models/accounts'
import * as urls from './urls'

export class AccountPostData implements IAccountPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async processSellAccounts(
    creditorId: string,
    cutOffDate?: Date,
    fileUploaded?: any,
    items?: types.ProcessSellAccountsItemsDto[]
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()

    form.append('creditorId', creditorId)
    form.append('cutOffDate', cutOffDate?.toISOString() ?? '')
    form.append('fileUploaded', fileUploaded)

    if (items && items.length > 0) {
      form.append('items', JSON.stringify(items))
    }

    return this.httpClient.post(urls.AccountsProcessSellAccounts, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async processAccountFile(
    creditorId: string,
    strategyId: Number,
    fileData: types.AccountFileData[],
    fileUploaded: any,
    overloadedVendor: string[] | null,
    isRestingArea: boolean
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('strategyId', strategyId ? strategyId.toString() : '')
    form.append('accounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    form.append(
      'overloadedVendors',
      overloadedVendor ? JSON.stringify(overloadedVendor) : ''
    )
    form.append('isRestingArea', isRestingArea.toString())
    return this.httpClient.post(urls.AccountsProcessAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  processRecallAccountFile(
    creditorId: string,
    fileData: types.RecallAccountFileData[],
    fileUploaded: any
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('recallAccounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(urls.AccountsProcessRecallAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  processReturnAccountFile(
    vendorId: string,
    fileData: types.RecallAccountFileData[],
    fileUploaded: any
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('vendorId', vendorId)
    form.append('returnAccounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(urls.AccountsProcessReturnAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  processRecallBusinessAccounts(
    businessId: string,
    accounts: types.RecallBusinessAccount[]
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append('accounts', JSON.stringify(Array.from(accounts)))
    return this.httpClient.post(
      urls.AccountsProcessRecallBusinessAccounts,
      form
    )
  }

  processReturnBusinessAccounts(
    businessId: string,
    accounts: types.RecallBusinessAccount[]
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append('accounts', JSON.stringify(Array.from(accounts)))
    return this.httpClient.post(
      urls.AccountsProcessReturnBusinessAccounts,
      form
    )
  }

  checkVendorAccountLoad(
    strategyId: Number,
    fileData: types.AccountFileData[]
  ): Promise<types.OverloadedVendor[] | ApiError> {
    const form = new FormData()
    form.append('strategyId', strategyId.toString())
    form.append('accounts', JSON.stringify(Array.from(fileData)))
    return this.httpClient.post(urls.AccountsCheckVendorAccountLoad, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async processUpdateAccountFile(
    creditorId: string,
    fileData: types.UpdateAccountFileData[],
    fileUploaded: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('accounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(urls.AccountsProcessUpdateAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  async processSendAccountToStrategy(
    creditorId: string,
    fileData?: string[],
    fileUploaded?: any,
    filterAccounts?: any,
    strategy?: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('strategyId', strategy ?? '')
    form.append('filterAccounts', JSON.stringify(filterAccounts ?? ''))
    form.append('accounts', JSON.stringify(Array.from(fileData ?? '')))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(
      urls.AccountsProcessSendAccountToStrategy,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }

  async processChargeOffAccounts(
    creditorId: string,
    isRestingArea: boolean,
    fileData?: any[],
    fileUploaded?: any,
    filterAccounts?: any,
    chargeOffOptions?: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('isRestingArea', isRestingArea.toString())
    form.append('filterAccounts', JSON.stringify(filterAccounts ?? ''))
    form.append('accounts', JSON.stringify(Array.from(fileData ?? '')))
    form.append('fileUploaded', fileUploaded)
    form.append('chargeOffOptions', JSON.stringify(chargeOffOptions ?? ''))
    return this.httpClient.post(urls.AccountsProcessChargeOffAccounts, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async filterAccounts(
    request: types.FilterAccountsRequest
  ): Promise<types.FilterAccountsResponse> {
    const response = await this.httpClient.post(
      urls.AccountsFilterAccounts,
      request
    )
    return response.data
  }

  async splitAccounts(
    request: types.SplitAccountsRequest
  ): Promise<types.SplitAccountsResponse[]> {
    const response = await this.httpClient.post(
      urls.AccountsSplitAccounts,
      request
    )
    return response.data
  }
}
