import { ErrorToast, SuccessToast, WarningToast } from 'everchain-uilibrary'
import React, { useState } from 'react'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IPostPlacementPostOperations } from 'src/domain/features/post/postPlacement/postPlacement'
import { useMutation } from '@tanstack/react-query'
import { AccountsTemplateEnum, BulkRespondOperation } from 'src/utils/constants'
import { generateFileBlob } from 'src/utils/file/fileGenerator'
import BulkPostPlacementBasePage, {
  BulkPostPlacementBaseFormFilter,
} from '../components/BulkPostPlacementBasePage'
import { useRecoverGridFilter } from 'src/context/RecoverGridFilterContext'

interface BulkPostPlacementWithdrawParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
  postPlacementPostOperations?: IPostPlacementPostOperations
}

const BulkPostPlacementWithdraw: React.FC<BulkPostPlacementWithdrawParams> = ({
  businessOperations,
  postPlacementOperations,
  postPlacementPostOperations,
}) => {
  const { placerFilter, setPlacerFilter } = useRecoverGridFilter()
  const [form, setForm] = useState<BulkPostPlacementBaseFormFilter>({
    creditorId: placerFilter,
    vendorId: undefined,
    last4SSN: undefined,
    accountIDs: undefined,
    postPlacementType: undefined,
    type: undefined,
  })
  const [action, setAction] = useState<string>('response')
  const [processedFile, setProcessedFile] = useState<any[]>([])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)

  const mutationGetTemplate = useMutation({
    mutationFn: async () => {
      return postPlacementOperations?.getTemplateFile(
        AccountsTemplateEnum.PostPlacementWithdrae
      )
    },
    onSuccess: async (response: any) => {
      generateFileBlob(response)
    },
    onError: async () => {
      ErrorToast('Error downloading template')
    },
  })

  const mutationUploadFile = useMutation({
    mutationFn: async () => {
      setIsProcessing(true)
      return postPlacementPostOperations?.uploadBulkRespond(
        form?.type,
        processedFile,
        BulkRespondOperation.Withdraw
      )
    },
    onSuccess: async (response: any) => {
      setShowUploadDialog(false)
      setIsProcessing(false)
      if (response?.data?.isSuccess) {
        if (response?.data?.data?.errors?.length === 0) {
          SuccessToast('Post Placement Withdraw created')
        } else {
          // TODO: REMOVE IT WHEN RESULT PAGE CREATED
          WarningToast('Post Placement Withdraw created')
          response?.data?.data?.errors.forEach((error: any) => {
            WarningToast(`Row: ${error.rowIndex} Error: ${error.title}`)
          })
        }
      } else {
        ErrorToast('Error processing file')
      }
    },
    onError: async (response: any) => {
      setIsProcessing(false)
    },
  })

  const GetPostPlacementRespondFileHeaders = () => {
    const headers: string[] = []
    headers.push('ID')
    headers.push('Reason')
    return headers
  }

  const processFile = (rawData: any[]) => {
    let items = rawData.map((_item: any) => {
      const normalizedItem = Object.fromEntries(
        Object.entries(_item).map(([key, value]) => [key.toLowerCase(), value])
      )

      return {
        rowIndex: normalizedItem.rowindex,
        requestId: normalizedItem.id,
        withdrawReason: normalizedItem.reason,
      }
    })

    setProcessedFile(items)
  }

  return (
    <BulkPostPlacementBasePage
      businessOperations={businessOperations}
      postPlacementOperations={postPlacementOperations}
      pageTitle="Withdraw"
      showActionDropDown={false}
      formFilter={form}
      setForm={setForm}
      mutationGetTemplate={mutationGetTemplate}
      fileHeaders={GetPostPlacementRespondFileHeaders()}
      processFile={processFile}
      mutationUploadFile={mutationUploadFile}
      isProcessing={isProcessing}
      showUploadDialog={showUploadDialog}
      setShowUploadDialog={setShowUploadDialog}
      action={action}
      setAction={setAction}
      setPlacerFilter={setPlacerFilter}
    ></BulkPostPlacementBasePage>
  )
}

export default BulkPostPlacementWithdraw
