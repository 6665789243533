import {
  Box,
  Colors,
  Dropdown,
  Flex,
  fNumberCurrency,
  Grid,
  Icon,
  IconButton,
  PercentInput,
  SwitchType,
  Typography,
} from 'everchain-uilibrary'
import React, { useState } from 'react'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import Placers from '../../Accounts/components/Placers'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { AssetType } from 'src/domain/models/assetTypes/assetTypes'
import { useCustomQuery } from 'src/infra/reactQuery'
import {
  FilterAccountsRequest,
  FilterAccountsResponse,
} from 'src/domain/models/accounts'
import { FormFilter } from '..'
import { profileCountry } from 'src/utils/user'

interface SellAccountsFilterStepProps {
  businessOperations?: IBusinessGetOperations
  accountPostOperations: IAccountPostOperations
  filterAccounts: FormFilter | undefined
  assetTypes: AssetType[]
  placerId: string | undefined
  setPlacerId: (value: string | undefined) => void
  assetTypeId: number | undefined
  setAssetTypeId: (value: number | undefined) => void
  isMultiPortfolio: boolean
  setIsMultiPortfolio: (value: boolean) => void
  filterAccountsData: FilterAccountsResponse | undefined
  setFilterAccountsData: (data: FilterAccountsResponse | undefined) => void
  loadingData: boolean
}

const SellAccountsFilterStep: React.FC<SellAccountsFilterStepProps> = ({
  businessOperations,
  accountPostOperations,
  filterAccounts,
  assetTypes,
  placerId,
  setPlacerId,
  assetTypeId,
  setAssetTypeId,
  isMultiPortfolio,
  setIsMultiPortfolio,
  filterAccountsData,
  setFilterAccountsData,
  loadingData,
}: SellAccountsFilterStepProps) => {
  const [percentAccounts, setPercentAccounts] = useState<number | undefined>(1)
  const [hasError, setHasError] = useState<boolean>(false)
  const [hasEdit, setHasEdit] = useState<boolean>(true)
  const [filterAccountsRequest, setFilterAccountsRequest] = useState<
    FilterAccountsRequest | undefined
  >()

  const { isFetching: loadingFilterAccountsData } =
    useCustomQuery<FilterAccountsResponse>(
      ['filterAccounts', filterAccountsRequest],
      async () =>
        accountPostOperations
          ?.filterAccounts(filterAccountsRequest!)
          .then((data) => {
            setFilterAccountsData(data)
            return data
          })
          .finally(() => {
            setHasEdit(false)
            setFilterAccountsRequest(undefined)
          }),
      { cacheTime: 0, enabled: !!filterAccountsRequest }
    )

  const handleRefresh = () => {
    if (!placerId || !assetTypeId || !percentAccounts) {
      setHasError(true)
      return
    }

    setHasError(false)
    setFilterAccountsData(undefined)
    setFilterAccountsRequest({
      businessId: placerId,
      assetTypeId,
      percentage: percentAccounts,
      filterAccounts: JSON.stringify(filterAccounts ?? ''),
    })
  }

  const onChangeParameters = () => {
    setFilterAccountsData(undefined)
    setHasEdit(true)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Placers
          businessOperations={businessOperations}
          onPlacersUpdate={(placer) => {
            onChangeParameters()
            setPlacerId(placer)
          }}
          selectedPlacer={placerId}
        />
      </Grid>
      <Grid item xs={12}>
        <PercentInput
          placeholder="Percentage of Accounts from Rest Area"
          value={percentAccounts}
          onChange={(e, value) => {
            onChangeParameters()
            setPercentAccounts(value ? Number(value) : undefined)
          }}
          width="300px"
          errormessage={hasError && !percentAccounts ? 'Required' : undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          isLoading={loadingData}
          id="assetTypeId-dropdown"
          placeholder="Asset Type"
          value={assetTypeId ?? filterAccounts?.assetTypeId ?? undefined}
          width="300px"
          allowEmptyValue
          onChange={(option?: any) => {
            onChangeParameters()
            setAssetTypeId(option?.id)
          }}
          options={assetTypes?.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          )}
          valueOptionName="id"
          labelOptionName="name"
          disabled={!placerId}
          errormessage={hasError && !assetTypeId ? 'Required' : undefined}
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', gap: 5 }}>
        <Box display="flex" flexDirection="column">
          <Typography
            isLoading={loadingFilterAccountsData}
            skeletonHeight="20px"
          >
            Total Face Value:{' '}
            {filterAccountsData?.totalFaceValue !== undefined && !hasEdit
              ? fNumberCurrency(
                  filterAccountsData?.totalFaceValue,
                  profileCountry()
                )
              : '*****'}
          </Typography>
          <Typography
            isLoading={loadingFilterAccountsData}
            skeletonHeight="20px"
          >
            Quantity of Accounts:{' '}
            {filterAccountsData?.totalAccounts !== undefined && !hasEdit
              ? filterAccountsData?.totalAccounts
              : '*****'}
          </Typography>
        </Box>
        <Flex alignItems="center" mb={2}>
          <IconButton onClick={handleRefresh}>
            <Icon name="Refresh" />
          </IconButton>
          {hasEdit && !loadingFilterAccountsData && (
            <Box>
              <Typography skeletonHeight="20px" color={Colors.warning}>
                There are changes on the parameters.
              </Typography>
              <Typography skeletonHeight="20px" color={Colors.warning}>
                Click on the refresh button to see the updated values.
              </Typography>
            </Box>
          )}
          {!hasEdit &&
            !loadingFilterAccountsData &&
            filterAccountsData?.totalAccounts &&
            percentAccounts !== 1 && (
              <Box>
                <Typography skeletonHeight="20px" color={Colors.success}>
                  This process selects accounts randomly.
                </Typography>
                <Typography skeletonHeight="20px" color={Colors.success}>
                  You can click on refresh again to select other accounts.
                </Typography>
              </Box>
            )}
          {!hasEdit &&
            !loadingFilterAccountsData &&
            !filterAccountsData?.totalAccounts && (
              <Typography skeletonHeight="20px" color={Colors.error}>
                The selected filters did not return any accounts.
              </Typography>
            )}
        </Flex>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <Typography variant="body1">
          How many portfolios will be created based on the selected accounts?
        </Typography>
        <SwitchType
          checked={isMultiPortfolio}
          onChange={(e) => setIsMultiPortfolio(e.target.checked)}
          variant="primary"
          primary="Single Portfolio"
          secondary="Multiple Portfolios"
        />
      </Grid>
    </Grid>
  )
}

export default SellAccountsFilterStep
